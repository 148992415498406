import React, { useEffect } from "react";
import { i18n } from "app/i18n";
import { Link, Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { builderSlice } from "app/stores/builder";
import { clearBuilderHistory } from "app/utils/visual";
import { builderApi } from "app/stores/builder";

export default function Builder() {
  const { pathname } = useLocation();
  const { project } = useParams();

  const dispatch = useDispatch();

  const tabs = [
    { name: "sections.title", href: "builder/sections" },
    { name: "templates.title", href: "builder/templates" },
    { name: "postTypes.title", href: "builder/posttypes" },
    { name: "contentTypes.title", href: "builder/contenttypes" },
  ];

  const isCurrent = (index) => {
    return pathname.includes(tabs[index].href);
  };

  useEffect(() => {
    clearBuilderHistory(dispatch, builderSlice);
  }, [pathname, dispatch]);

  if (!project) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center justify-center py-4 px-6 bg-white border-b border-b-primary-50">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <Link
              to={`/project/${project}/${tab.href}`}
              key={tab.name}
              aria-current={isCurrent(index) ? "page" : undefined}
              className={`border px-6 py-3 font-medium text-sm rounded-md main-transition ${
                isCurrent(index) ? "border-primary-500 text-primary-500" : "border-white text-text hover:text-primary-500"
              }`}
            >
              {i18n(tab.name)}
            </Link>
          ))}
        </nav>
      </div>
      <Outlet />
    </div>
  );
}
