import Loading from "app/components/atoms/loading";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import ModalSection from "app/components/molecules/modals/modalSection";
import SortableTree from "app/components/organisms/buildernew";
import History from "app/components/organisms/buildernew/components/history";
import { i18n } from "app/i18n";
import { setActiveHistory, useDeleteSectionMutation, useGetSectionQuery, useUpdateSectionMutation } from "app/stores/builder";
import { getSource } from "app/utils/content";
import { showServerError } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { builderApi } from "app/stores/builder";
import ModalUsageLocation from "app/components/molecules/modals/modalUsageLocation";

export default function SectionsEdit(props) {
  const { reloadList, onDuplicate } = props;

  // ROUTER
  const navigate = useNavigate();
  let { project, id } = useParams();

  // STATE
  const [timer, setTimer] = useState(null);
  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [previewModal, setPreviewModal] = useState(null);
  const [modalUsageLocation, setModalUsageLocation] = useState(null);

  // STORE
  const dispatch = useDispatch();
  const { active, history } = useSelector((store) => store.builder);

  // QUERY
  let { data, isLoading: isGetting, isFetching, isUninitialized, isError: isErrorGet } = useGetSectionQuery({ id, project }, { skip: !id || !project, refetchOnReconnect: true });

  // MUTATION
  const [updateSection, { isLoading: isUpdating, isError: isErrorUpdate }] = useUpdateSectionMutation();
  const [deleteSection, { isLoading: isDeleting, isError: isErrorDelete }] = useDeleteSectionMutation();

  // VARS
  const isLoading = isFetching || isGetting || isUpdating || isDeleting;
  const isError = isErrorGet || isErrorUpdate || isErrorDelete;

  useEffect(() => {
    return () => {
      setStructure(null);
      dispatch(builderApi.util.invalidateTags([{ type: "Section" }]));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // EDIT SECTION SETTINGS
  const onEdit = () => {
    setModal({
      section: data,
      isOpen: true,
      project: project,
      buttonText: i18n("button.update_section"),
      onSubmit: ({ id, title, key, thumbnail }) => {
        updateSection({ id, title, key, thumbnail }).then((res) => {
          if (res?.data) {
            reloadList();
            dispatch(builderApi.util.invalidateTags([{ type: "Section" }]));
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  // DROP ALL INPUTS IN STRUCTURE
  const onEmptize = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.clean_all_inputs"),
      forceNotice: data.inUse,
      notice: i18n("alert.action_clean_page"),
      onConfirm: () => {
        updateSection({ id: data.id, structure: [] }).then((res) => {
          if (res?.data) {
            setStructure([]);
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // ON DUPLICATE
  const onClickDuplicate = () => {
    onDuplicate(data?.structure);
  };

  // DELETE SECTION
  const onDelete = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      buttonText: i18n("button.delete"),
      title: i18n("label.delete_section"),
      text: i18n("alert.section_delete"),
      mode: "REQUIRED",
      forceNotice: data.inUse,
      notice: i18n("alert.section_in_use"),
      onConfirm: () => {
        deleteSection({ id: data.id }).then((res) => {
          if (res && res.data) {
            reloadList();
            navigate(`/project/${project}/builder/sections`);
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // OPEN THUMBNAIL
  const onOpenPreview = () => {
    setPreviewModal({
      isOpen: true,
      href: getSource(data?.thumbnail?.href),
      onClose: () => setPreviewModal(null),
    });
  };

  // OPOEN MODAL
  const onOpenUsageLocation = () => {
    setModalUsageLocation({
      isOpen: true,
      data: data,
      modalType: "section",
      onClose: () => setModalUsageLocation(null),
    });
  };

  // ON SAVE STRUCTURE
  function onSave(structure, history) {
    updateSection({ id: data.id, structure, history }).then((res) => {
      if (res?.data) {
        if (!history) setStructure(res?.data?.structure);
      } else {
        showServerError(res);
      }
    });
  }

  // UPDATE FROM HISTORY
  const onUseHistory = (isNext) => {
    if (!history || history?.length < 2) return null;

    const direction = isNext ? -1 : 1;
    const newActive = active + direction;

    if (newActive >= history.length || newActive < 0 || !history.at(newActive)) return null;

    setStructure(history.at(newActive));
    dispatch(setActiveHistory(newActive));

    // STOP TIMER IF EXISTS
    if (timer) clearTimeout(timer);

    // UPDATE IN DATABASE
    const newTimer = setTimeout(() => {
      onSave(history.at(newActive), true);
    }, 1400);

    // SET NEW TIMER
    setTimer(newTimer);
  };

  const sectionId = data?.id;
  useEffect(() => {
    if (sectionId) setStructure(data?.structure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  if (!id) return <Navigate to={`/project/${project}/builder/sections`} />;
  if (isUninitialized) return <Loading active className="h-full flex items-center justify-center" />;

  return (
    <>
      <SortableTree
        isSection
        title={data?.title}
        slug={data?.key}
        isLoading={isLoading}
        isError={isError}
        initialItems={structure ? [...structure] : []}
        onSave={onSave}
        // showFavorites={hasPermission(user, "DEVELOPER")}
      >
        <History active={active} length={history?.length || 0} onNext={() => onUseHistory(true)} onPrev={() => onUseHistory(false)} />
        {data?.thumbnail?.href && (
          <button className="btn-primary-outline mx-2" onClick={onOpenPreview}>
            {i18n("label.preview")}
          </button>
        )}
        <Dropdown
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
            { title: i18n("button.duplicate"), onClick: onClickDuplicate, icon: "copy" },
            { title: i18n("button.usage_location"), onClick: onOpenUsageLocation, icon: "info" },
            { title: i18n("button.cleanStructure"), onClick: onEmptize, icon: "clean" },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true },
          ]}
        />
      </SortableTree>

      <ModalUsageLocation {...modalUsageLocation} />
      <ModalSection {...modal} />
      <ModalPreview {...previewModal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
