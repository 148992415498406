import LabelSyncStatus from "app/components/molecules/labels/labelSyncStatus";
import ModalComponent from "app/components/molecules/modals/modalComponent";
import ModalComponentCustom from "app/components/molecules/modals/modalCreateComponentCustom/index_2";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import { i18n } from "app/i18n";
import { useAddCustomInputMutation } from "app/stores/builders";
import { showServerError } from "app/utils/server";
import { prepareCustomInputs } from "app/utils/visual";
import { useState } from "react";
import { useSelector } from "react-redux";
import shortid from "shortid";
import DragElement from "./DragElement";
import DragElementCustom from "./DragElementCustom";
import Dropzone from "./Dropzone";
import { getFieldsOfLevel, isKeyAvailableInArray } from "./helpers";
import elements from "./helpers/elements";

export default function BuilderConstructor(props) {
  const { data, options = elements, posttype, basic = false, showFavorites, refetch, placeholder, isLoading, isError, children, onUpdate } = props;

  // STATE
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState(null);
  const [customModal, setCustomModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);
  const [isDragElementsOpen, setDragElementsOpen] = useState(true);

  // STORE
  const { customInputs } = useSelector((store) => store.builder);

  // REQUEST
  const [updateCustomInputs, { isLoading: isLoadingUpdateCustom }] = useAddCustomInputMutation();

  // VAR
  let layout = [];
  if (basic && data && data.sections) layout = data.sections.map((item) => ({ id: item.id, title: item.section.title, section: item.section.id }));
  else if (data && data.structure) layout = data.structure;

  // FUNCTION TO WHEN USER CLICK IN SIDEBAR BUTTON INSTEAD DRAG
  const addToEnd = (node, custom) => {
    if (basic) {
      onUpdate([...layout, { ...node }]);
    } else if (custom) {
      setModal({
        isOpen: true,
        custom: true,
        inputType: node.type,
        inputs: getFieldsOfLevel(layout, []),
        validateKey: (key) => isKeyAvailableInArray(null, layout, key, "none"),
        component: { key: node.key, title: node.title, isList: node.isList },
        onSubmit: (values) => {
          onUpdate([...layout, { ...node, ...values, model: node.model, id: shortid.generate(), blocked: true, expanded: false }]);
          setModal(null);
        },
        onClose: () => setModal(null),
      });
    } else {
      setModal({
        isOpen: true,
        inPostType: posttype,
        inputType: node.type,
        onSubmit: (values) => {
          onUpdate([...layout, { ...node, ...values, id: shortid.generate() }]);
          setModal(null);
        },
        onClose: () => setModal(null),
      });
    }
  };

  // DELETE CUSTOM ELEMENT FROM LIST
  const editCustomElement = (input) => {
    setCustomModal({
      isOpen: true,
      component: input,
      onClose: () => setCustomModal(null),
      onSubmit: (values) => {
        const value = prepareCustomInputs(customInputs, { ...input, ...values });
        updateCustomInputs({ key: "builder", value: value }).then((res) => {
          if (res?.data) {
            setCustomModal(null);
            if (refetch) {
              refetch();
            }
          } else {
            showServerError(res);
          }
        });
      },
      onDelete: () => {
        setModalConfirm({
          isOpen: true,
          type: "DELETE",
          onClose: () => setModalConfirm(null),
          onConfirm: () => {
            const value = prepareCustomInputs(customInputs, input, true);
            updateCustomInputs({ key: "builder", value: value }).then((res) => {
              if (res?.data) {
                setCustomModal(null);
                setModalConfirm(null);
              } else {
                showServerError(res);
              }
            });
          },
        });
      },
    });
  };

  const toggleDragElements = () => setDragElementsOpen(!isDragElementsOpen);

  return (
    <div className="flex flex-row h-full flex-1">
      <div className="p-6 flex flex-col flex-1">
        {/* HEADER */}
        <div className="flex justify-between items-center flex-wrap">
          <div className="mt-3">
            <h1 className="text-text font-bold text-lg leading-none">{data.title}</h1>
            <span className="text-sm text-gray-500">{data.key}</span>
          </div>
          <div className="flex items-center mt-3">
            <LabelSyncStatus isLoading={isLoading} isError={isError} />
            <div className="flex justify-end items-center w-full lg:w-fit">{children}</div>
          </div>
        </div>
        {/* DROP AREA */}
        <div className="mt-8 z-10 flex-1">
          <Dropzone custom={customInputs} posttype={posttype} data={layout} basic={basic} placeholder={placeholder} onUpdate={onUpdate} elementInUse={!!data?.inUse} blockInput={!!data?.webservice} />
        </div>
      </div>
      <div className={`relative peer text-sm p-6 flex flex-col border-l ${isDragElementsOpen ? "w-56 xl:w-72" : "w-0 px-0"} transition-all duration-500 ease-in-out overflow-auto`}>
        <button
          onClick={toggleDragElements}
          className="block xl:hidden absolute -left-3 top-0 bottom-0 m-auto w-fit py-[2px] z-10 px-4 h-fit -rotate-90 origin-left text-sm border font-xs rounded-t-md bg-primary-900 text-white hover:bg-primary-500 transition-all duration-300 ease-in-out"
        >
          {isDragElementsOpen ? i18n("button.hide") : i18n("button.show")}
        </button>
        {showFavorites && (
          <div className={`${customInputs?.length ? "h-10 mb-4" : "h-0 mb-0"} flex gap-2 transition-all duration-500 overflow-hidden`}>
            <button type="button" onClick={() => setTab(0)} className={`flex-1 border rounded-lg p-2 transition duration-500 ${tab === 0 ? "bg-primary-700 text-white" : "bg-white"}`}>
              Inputs
            </button>
            <button type="button" onClick={() => setTab(1)} className={`flex-1 border rounded-lg p-2 transition duration-500 ${tab === 1 ? "bg-primary-700 text-white" : "bg-white"}`}>
              Custom
            </button>
          </div>
        )}
        <div className="flex-1 relative">
          <div
            className={`transition duration-500 w-full ${
              !customInputs?.length || tab === 0 ? "relative opacity-100 translate-y-0" : "absolute top-0 left-0 right-0 opacity-0 translate-y-40 pointer-events-none"
            }`}
          >
            {options.map((element, key) => (
              <DragElement key={key} node={{ ...element }} onClick={() => addToEnd(element)} />
            ))}
          </div>
          {showFavorites && !!customInputs?.length && (
            <div className={`transition duration-500 w-full ${tab === 1 ? "relative opacity-100 translate-y-0" : "absolute top-0 left-0 right-0 opacity-0 translate-y-40 pointer-events-none"}`}>
              {customInputs.map((element, key) => (
                <DragElementCustom key={key} node={{ ...element, custom: true }} onEdit={() => editCustomElement(element)} onClick={() => addToEnd(element, true)} />
              ))}
            </div>
          )}
        </div>
      </div>

      <ModalComponent {...modal} />
      <ModalComponentCustom {...customModal} />
      <ModalConfirm {...modalConfirm} isLoading={isLoadingUpdateCustom} />
    </div>
  );
}
