import { i18n } from "app/i18n";
import { Content, Footer, Header, Struture } from "../../../atoms/modal";
import { getSource } from "app/utils/content";

export default function ModalPreview(props) {
  const { isOpen = false, list, href, onClose = () => {} } = props;
  return (
    <Struture isOpen={isOpen} size="sm" onClose={onClose} className="max-w-md">
      <Header onClose={onClose} />
      <Content noPad>
        {list?.length ? (
          <>
            {list.map(({ name, image }, key) => (
              <div key={key} className="w-full min-h-[200px] bg-slate-300 relative">
                <span className="absolute top-3 right-3 py-2 px-4 bg-white drop-shadow-2xl rounded-lg text-sm text-blue-700">{name}</span>
                {!!image && <img src={getSource(image)} alt="" className="w-full h-auto" />}
              </div>
            ))}
          </>
        ) : href ? (
          <img src={href} alt="" className="w-full h-auto" />
        ) : (
          <p className="p-10 text-center">{i18n("label.no_preview_available")}</p>
        )}
      </Content>
      <Footer cancel={{ text: i18n("button.close"), onClick: onClose }} />
    </Struture>
  );
}
