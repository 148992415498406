import { useMutation } from "@apollo/client";
import Dropzone from "app/components/atoms/inputs/dropzone";
import { getCookie, showServerError, UPLOAD_MUTATION } from "app/utils/server";
import { useSelector } from "react-redux";

export default function UploadArea(props) {
  const { forceType = undefined, formats, loading = true, reload, select } = props;

  // STORE
  const { project } = useSelector((state) => state.project);

  // MUTATIONS
  const [uploadMedia, { loading: isUploading }] = useMutation(UPLOAD_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    },
  });

  // WHEN UPLOADED
  const onChange = (files, valid) => {
    if (files?.length && valid) {
      uploadMedia({ variables: { files: files, project: project.id, forceType: forceType || undefined } }).then((res) => {
        if (res?.data) {
          // RELOAD LIST
          if (reload) reload();
          // SELECT UPLOADED FILES
          if (res?.data?.createMedia?.length) select(res.data.createMedia.map((item) => item.id));
        } else {
          showServerError(res);
        }
      });
    }
  };

  const isLoading = loading || isUploading;

  return (
    <div>
      <Dropzone multi={forceType ? false : true} onChange={onChange} formats={formats} isLoading={isLoading} disabled={isLoading} />
    </div>
  );
}
