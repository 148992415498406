import { useEffect, useState } from "react";

// Atoms
import { Struture, Header, Content, Footer } from "../../../atoms/modal";

// Organisms
import { i18n } from "app/i18n";

export default function ModalUsageLocation({ isOpen = false, modalType, data = {}, isLoading, onClose = () => {} }) {
  const items = modalType === "template" ? data.posts || [] : data.templates || [];
  const noItemsLabel = modalType === "template" ? i18n("label.no_posts") : i18n("label.no_templates");
  const headerTitle = modalType === "template" ? i18n("label.pages_using_this_template") : i18n("label.templates_using_this_section");
  const contentTitle = modalType === "template" ? i18n("label.text_associated_to_template") : i18n("label.text_associated_to_section");

  const renderItem = (item, key) => {
    const isTemplate = modalType === "template";
    const { translations = [] } = item;
    const title = isTemplate ? translations[0]?.title || i18n("label.no_title") : item.template?.title || i18n("label.no_title");
    const translation = translations[0];

    return (
      <li key={key}>
        <div className="transition-all duration-300 ease-linear w-full group flex flex-col px-4 py-3 text-sm font-medium rounded-md text-gray-600 bg-slate-50">
          <span>{title}</span>

          {isTemplate && translation && (
            <span>
              {i18n("label.published")}: {translation.published ? i18n("label.yes") : i18n("label.no")}
            </span>
          )}

          {isTemplate && translations.length > 0 && (
            <div>
              <span>
                {i18n("label.language")}: {translations.map(({ language: { slug } }) => slug).join(", ")}
              </span>
            </div>
          )}
        </div>
      </li>
    );
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={headerTitle} onClose={onClose} />
      <Content>
        <div>
          <p className="text-text text-lg">{contentTitle}</p>
          {items.length ? <ul className="flex flex-col space-y-1 mt-2">{items.map(renderItem)}</ul> : <p className="text-sm text-gray-300">{noItemsLabel}</p>}
        </div>
      </Content>
      <Footer
        loading={isLoading}
        cancel={{
          text: i18n("button.close"),
          onClick: onClose,
        }}
      />
    </Struture>
  );
}
