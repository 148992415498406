import { useMutation } from "@apollo/client";
import Icon from "app/components/atoms/icons/icon";
import Loading from "app/components/atoms/loading";
import MediaDetail from "app/components/organisms/media/detail";
import MediaFilters from "app/components/organisms/media/filters";
import MediaList from "app/components/organisms/media/list";
import MediaTabs from "app/components/organisms/media/tabs";
import { i18n } from "app/i18n";
import { filesSlice, setSkip, setView, useGetMediaDetailsQuery, useGetMediaQuery, useGetMediaTotalQuery } from "app/stores/files";
import { getCookie, showServerError, showServerSuccess, UPLOAD_MUTATION } from "app/utils/server";
import { addFilesToMediaList } from "app/utils/visual";
import { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

export default function Multimedia() {
  // REF
  const sentinelRef = useRef(null);

  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { views, mediaView } = useSelector((state) => state.file);
  const { project } = useSelector((state) => state.project);
  const { files, filesSelected, take, skip, page, where, orderBy } = useSelector((state) => state.file);

  // STATE
  const [current, setCurrent] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [keyControl, setKeyControl] = useState({ shift: false, ctrl: false });

  // QUERIES
  const {
    data: total,
    isLoading: isLoadingTotal,
    isUninitialized: isUninitializedTotal,
    refetch: refetchTotal,
  } = useGetMediaTotalQuery({ where: { project: { id: project?.id } } }, { skip: !project?.id });
  const { isLoading, isFetching, isUninitialized } = useGetMediaQuery(
    { take, skip, page, where: { ...where, project: { id: project?.id } }, orderBy },
    { skip: !project?.id, refetchOnMountOrArgChange: true }
  );
  const { isLoading: isLoadingDetails } = useGetMediaDetailsQuery({ current }, { refetchOnMountOrArgChange: true });

  // MUTATIONS
  const [uploadMedia, { loading: isLoadingUpload }] = useMutation(UPLOAD_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${getCookie("access_token")}`,
      },
    },
  });

  // VARS
  const loading = isFetching || isLoading || isLoadingTotal || isLoadingDetails || isLoadingUpload || isUninitialized || isUninitializedTotal;

  // CHECK KEY PRESSED
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // FILE SELECTION
  useEffect(() => {
    if (filesSelected && filesSelected.length) setCurrent(filesSelected.map((item) => item.id));
    else setCurrent(null);
  }, [filesSelected]);

  // SENTINEL TO LOAD MORE
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) fetchData();
      },
      { threshold: 0.5 }
    );
    const listSentinel = sentinelRef ? sentinelRef.current : undefined;

    if (listSentinel) observer.observe(listSentinel);
    return () => {
      if (listSentinel) observer.unobserve(listSentinel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentinelRef, files]);

  // ACTION FOR KEY DOWN
  const handleKeyDown = (event) => {
    if (event.shiftKey || event.ctrlKey || event.metaKey) setKeyControl({ shift: event.shiftKey, ctrl: event.ctrlKey || event.metaKey });
  };

  // ACTION FOR KEY UP
  const handleKeyUp = () => {
    setKeyControl({ shift: false, ctrl: false });
  };

  // ACTION FOR SELECTION
  const onSelect = (id) => {
    let temp = current ? [...current] : [];
    const currentActive = temp.findIndex((e) => e === id);
    if (keyControl.ctrl) {
      if (currentActive !== -1) temp.splice(currentActive, 1);
      else temp.push(id);
    } else if (keyControl.shift) {
      if (currentActive !== -1) {
        temp.splice(currentActive, 1);
      } else if (temp.length) {
        const lastIndex = files.findIndex((file) => file.id === temp[temp.length - 1]);
        const clickIndex = files.findIndex((file) => file.id === id);
        files.filter((f, k) => k >= Math.min(lastIndex, clickIndex) && k <= Math.max(lastIndex, clickIndex)).forEach((file) => temp.push(file.id));
      } else {
        temp = [id];
      }
    } else {
      temp = [id];
    }
    setCurrent(temp?.length ? [...temp] : null);
  };

  // OPEN OR CLOSE FILTERS
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const fetchData = async () => {
    if (!loading && files?.length && skip !== files.length) dispatch(setSkip(files.length));
  };

  const onSelectAll = (status) => {
    if (status) setCurrent(files.map((file) => file.id));
    else setCurrent(null);
  };

  // ON DROP FILES
  const onDropFiles = (acceptedFiles) => {
    if (acceptedFiles?.length) {
      uploadMedia({ variables: { files: acceptedFiles, project: project.id } }).then((res) => {
        if (res?.data) {
          // UPLOAD ON LIST
          if (res?.data?.createMedia?.length) addFilesToMediaList(files, res.data.createMedia, false, filesSlice, dispatch);
          // UPLOAD TOTAL
          refetchTotal();
          showServerSuccess(i18n("toast.success_upload"));
        } else {
          showServerError(res);
        }
      });
    }
  };

  return (
    <>
      <div className="flex h-full">
        {/* Content area */}
        <div className="flex flex-col flex-1 overflow-hidden">
          {/* Main content */}
          <div className="flex items-stretch flex-1 overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="px-4 pt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="flex">
                  <h1 className="flex items-center flex-1 text-2xl font-bold text-gray-900">
                    {i18n("label.media")} <Loading active={loading} className="ml-2" />
                  </h1>
                  <div className="ml-6 flex items-center rounded-lg bg-gray-100 p-0.5 sm:hidden">
                    {views.map(({ label, icon }, key) => {
                      let className = "rounded-md p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ";
                      if (key) className += "ml-0.5 ";
                      if (mediaView === label) className += "bg-white ";
                      return (
                        <button type="button" key={key} className={className} onClick={() => dispatch(setView(label))}>
                          <Icon name={icon} width="w-5" height="h-5" />
                        </button>
                      );
                    })}
                  </div>
                </div>

                {/* Tabs */}
                <MediaTabs showFilters={showFilters} onClickFilter={toggleFilters} />

                {/* Filters */}
                <MediaFilters loading={loading} show={showFilters} />

                {/* <input type="file" onChange={handleUpload} /> */}

                {/* Gallery */}
                <Dropzone multiple={true} onDrop={(files) => onDropFiles(files)} noClick>
                  {({ getRootProps, getInputProps, isDragActive, isDragAccept }) => (
                    <div {...getRootProps()} className={`dropzone ${isDragActive ? " isDragActive" : ""}${isDragAccept ? " isDragAccept" : ""}`}>
                      <input {...getInputProps()} id="input-upload-media" />
                      <section className="pb-16 mt-8" aria-labelledby="gallery-heading">
                        <h2 id="gallery-heading" className="sr-only">
                          {i18n("label.recently_viewed")}
                        </h2>
                        <MediaList view={mediaView} files={files} current={current} onSelect={onSelect} onSelectAll={onSelectAll} />
                        <Loading active={loading} size="h-8 w-8" className="text-center mt-4" />
                      </section>
                      {files?.length && files?.length < total ? <div ref={sentinelRef} className="h-0.5"></div> : null}
                    </div>
                  )}
                </Dropzone>
              </div>
            </main>

            {/* Details sidebar */}
            <MediaDetail loading={loading} files={filesSelected} />
          </div>
        </div>
      </div>
    </>
  );
}
