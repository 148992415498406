import IconPlus from "app/components/atoms/icons/iconPlus";
import Loading from "app/components/atoms/loading";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPost from "app/components/molecules/modals/modalPost";
import Permission from "app/components/molecules/permission";
import ListTable from "app/components/molecules/table";
import { i18n } from "app/i18n";
import {
  setPage,
  setWhere,
  useCreatePostMutation,
  useCreatePostTranslationMutation,
  useDeletePostTranslationMutation,
  useDuplicatePostMutation,
  useGetPostsQuery,
  useGetPostsTotalQuery,
  useGetPostTypeQuery,
} from "app/stores/project";
import { getFlag } from "app/utils/languages";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Categories from "../listCategories";
import { headers } from "./constants";
import ItemPost from "./itemPost";

export default function ListPosts() {
  // PARAMS
  const { key } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // STORE
  const { project, language, languages, take, where, skip, orderBy } = useSelector((state) => state.project);

  // STATE
  const [modal, setModal] = useState(null);
  const [postsHeaders, setPostsHeaders] = useState(headers);
  const [confirmModal, setConfirmModal] = useState(null);

  // VARS 1
  const type = project?.posttypes?.find((e) => e.key === key);
  const skipRequest = !type?.id || !project?.id;

  // QUERY
  const { data: postType, isLoading: isGettingType } = useGetPostTypeQuery({ id: type?.id, project: project?.id }, { skip: skipRequest });
  const whereInput = { project: { id: project?.id }, postType: { id: postType?.id }, ...where };
  const { data: total, isLoading: isGettingTotal, refetch: updateTotal } = useGetPostsTotalQuery({ where: whereInput }, { skip: !postType?.id || !project?.id });
  const { data: posts, isLoading: isGettingPosts, refetch: updateList } = useGetPostsQuery({ take, skip, where: whereInput, orderBy }, { skip: !postType?.id || !project?.id });

  // MUTATION
  const [createPost, { isLoading: isCreating }] = useCreatePostMutation();
  const [duplicatePost, { isLoading: isDuplicating }] = useDuplicatePostMutation();
  const [createPostTranslation, { isLoading: isTranslating }] = useCreatePostTranslationMutation();
  const [deletePostTranslation, { isLoading: isDeleting }] = useDeletePostTranslationMutation();

  // VARS
  const isLoading = isGettingType || isGettingTotal || isGettingPosts || isCreating || isDuplicating || isTranslating || isDeleting;

  useEffect(() => {
    return () => dispatch(setPage(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  // SET LANGUAGES FOR TABLE
  useEffect(() => {
    if (languages?.length && headers?.length) {
      const temp = [...headers];
      const index = temp.findIndex((item) => item.isLang);
      if (index !== -1) temp[index].options = languages.map((item) => item.language.slug);
      setPostsHeaders([...temp]);
    }
  }, [languages]);

  // CALL UPDATE LIST AND TOTAL
  const dataChanges = () => {
    updateList();
    updateTotal();
  };

  // WHEN POST IS CREATED
  const onCreate = () => {
    setModal({
      project,
      language,
      post: null,
      isOpen: true,
      type: postType.key,
      templates: postType.templates,
      onSubmit: (data) => {
        createPost({ ...data, type: postType.id, project: project.id, language: language.id }).then((res) => {
          if (!res.error && res.data) {
            dataChanges();
            navigate(`${res.data.id}/${language.slug}`);
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  // CREATE TRANSLATION OF CONTENT FOR ANOTHER LANGUAGE
  const onCreateTranslation = (post, language) => {
    const availableTranslations = languages
      .filter((e) => post.translations.find((content) => content.language.slug === e.language.slug))
      .map((e) => {
        const title = post.translations.find((content) => content.language.slug === e.language.slug).title;
        return { value: e.language.id, label: `<span className="mr-1">${getFlag(e.language.slug)}</span> ${title}` };
      });

    setModal({
      post: null,
      isOpen: true,
      templates: null,
      duplicate: true,
      language: language?.language,
      type: postType.key,
      availableTranslations,
      onSubmit: ({ slug, title, copy, language: origin, translate }) => {
        createPostTranslation({ post: post.id, language: language.language.id, slug, title, copy, origin: origin?.value, translate }).then((res) => {
          if (!res.error && res.data) {
            navigate(`${res.data.post.id}/${res.data.language.slug}`);
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  // DUPLICATE A ENTIRE POST (NEW PAGE)
  const onDuplicate = (post) => {
    setModal({
      post,
      isOpen: true,
      duplicatePost: true,
      type: post.postType.key,
      templates: null,
      modalTitle: i18n("button.duplicate"),
      buttonText: i18n("button.duplicate"),
      onSubmit: ({ translations }) => {
        const data = Object.keys(translations).map((key) => ({ title: translations[key].title, slug: translations[key].slug, language: key }));
        duplicatePost({ data, post: post.id, project: project.id, language: language.id }).then((res) => {
          if (!res.error && res.data) {
            navigate(`${res.data.id}/${language.slug}`);
            dataChanges();
            showServerSuccess(i18n("toast.success_duplicate"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  // DELETE TRANSLATION (WILL DELETE POST IF NO TRANSLATIONS)
  const onDelete = (post) => {
    const options = post?.translations?.map((e) => ({ value: languages?.find((l) => l.language.slug === e.language.slug)?.language?.id, label: `${getFlag(e.language.slug)} ${e.title}` }));
    const currentTranslation = options?.length ? options[0] : undefined;

    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      mode: "INPUTS",
      inputs: [
        {
          type: "Toggle",
          title: "Delete Post",
          key: "complete",
          description: "With this action active, you will delete all translations of this post",
          texts: [i18n("label.delete_full_post"), i18n("label.delete_just_translation")],
        },
        {
          type: "Select",
          title: i18n("label.language"),
          placement: "top",
          key: "language",
          options: options,
          conditional: {
            field: "complete",
            when: "isFalse",
          },
        },
      ],
      defaultValues: {
        complete: false,
        language: currentTranslation,
      },
      onConfirm: (values) => {
        deletePostTranslation({ id: post.id, project: project.id, language: values?.language?.value, complete: !!values?.complete }).then((res) => {
          if (res.data) {
            dataChanges();
            showServerSuccess(i18n("toast.success_delete"));
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
      onClose: () => {
        setConfirmModal(null);
      },
    });
  };

  return (
    <div className="custom-container px-10 h-full">
      <div className="flex items-center justify-between pt-16 pb-10">
        <span className="flex items-center">
          <div>
            <h1 className="text_3xl">{postType && postType.title}</h1>
            <p className="text-xs text-slate-400 lowercase">
              {total} {total === 1 ? i18n("label.page") : i18n("label.pages")}
            </p>
          </div>
          <Loading active={isLoading} className="ml-3" />
        </span>
        {postType && project && (
          <div className="flex items-center gap-2">
            <Permission min="MANAGER" project dev>
              <button type="button" className="btn-primary-outline" onClick={onCreate}>
                <IconPlus />
                {i18n("button.create_page")}
              </button>
            </Permission>
            <Categories />
          </div>
        )}
      </div>
      <div>
        <ListTable headers={postsHeaders} setPage={setPage} setWhere={setWhere} store="project" total={total}>
          {!!posts?.length &&
            posts.map((post) => (
              <ItemPost {...post} key={post.id} onCreateTranslation={(language) => onCreateTranslation(post, language)} onDuplicate={() => onDuplicate(post)} onDelete={() => onDelete(post)} />
            ))}
        </ListTable>
      </div>

      <ModalPost {...modal} isLoading={isLoading} />
      <ModalConfirm {...confirmModal} isLoading={isLoading} />
    </div>
  );
}
