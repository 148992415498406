import { useDraggable } from "@dnd-kit/core";
import elements from "../elements";
import { useGetSectionsQuery } from "app/stores/builder";
import { useParams } from "react-router-dom";
import { i18n } from "app/i18n";
import { useEffect, useState } from "react";
import { EyeIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import Permission from "app/components/molecules/permission";

export default function Sidebar(props) {
  const { regKey, onClick, isSection, isTemplate, showThumbnail, onEditCustom } = props;

  // PARAMS
  let { project } = useParams();

  // STORE
  const { inputs } = useSelector((store) => store.builder);

  // STATE
  const [tab, setTab] = useState(0);

  // QUERY
  const {
    data: sections,
    isFetching,
    isLoading: isGetting,
    refetch: reloadList,
  } = useGetSectionsQuery(
    { where: { type: "PAGE", project: { id: project } }, orderBy: { title: "asc" } },
    {
      skip: !project || !isTemplate,
    }
  );

  useEffect(() => {
    if (sections && isTemplate) {
      reloadList();
    }
  }, [isTemplate, sections]);

  // VARS
  const isLoading = isFetching || isGetting;

  function handleEditCustom(item) {
    if (!item || !onEditCustom) return;
    onEditCustom(item);
  }

  const handlePreview = (item) => {
    if (!item?.thumbnail?.href) return;
    showThumbnail(item.thumbnail.href);
  };

  if (isTemplate && !isLoading && sections) {
    return (
      <div key={regKey} className="space-y-1 overflow-auto relative">
        {/* Templates */}
        {sections?.length > 0 ? (
          sections.map((input) => (
            <DragItem
              key={input.key}
              type={input.key}
              {...input}
              onClick={onClick}
              isSection
              thumbnail={input.thumbnail}
              handlePreview={(e) => {
                e.stopPropagation();
                handlePreview(input);
              }}
            />
          ))
        ) : (
          <p>{i18n("label.no_sections_available")}</p>
        )}
      </div>
    );
  } else {
    return (
      <div key={regKey} className="space-y-1 overflow-auto relative">
        {isSection && !!inputs?.length && (
          <Permission min="DEVELOPER">
            {/* Tabs CustomInput */}
            <div className={`${elements?.length ? "h-10 mb-4" : "h-0 mb-0"} flex gap-2 transition-all duration-500 overflow-hidden`}>
              <button type="button" onClick={() => setTab(0)} className={`flex-1 border rounded-lg p-2 transition duration-500 ${tab === 0 ? "bg-primary-700 text-white" : "bg-white"}`}>
                {i18n("label.inputs")}
              </button>
              <button type="button" onClick={() => setTab(1)} className={`flex-1 border rounded-lg p-2 transition duration-500 ${tab === 1 ? "bg-primary-700 text-white" : "bg-white"}`}>
                {i18n("label.custom")}
              </button>
            </div>
            {/* Custom Inputs */}
            <div className={`transition duration-500 w-full ${tab === 1 ? "relative opacity-100 translate-y-0" : "absolute top-0 left-0 right-0 opacity-0 translate-y-40 pointer-events-none"}`}>
              {inputs.map((input) => (
                <DragItem
                  key={input.key}
                  customId={input.id}
                  children={input.children}
                  {...input}
                  onClick={onClick}
                  isCustomInput={onEditCustom ? true : null}
                  handleEdit={
                    onEditCustom
                      ? (e) => {
                          e.stopPropagation();
                          handleEditCustom(input);
                        }
                      : null
                  }
                />
              ))}
            </div>
          </Permission>
        )}

        {/* Normal Inputs */}
        <div
          className={`transition duration-500 w-full ${
            !elements?.length || tab === 0 ? "relative opacity-100 translate-y-0" : "absolute top-0 left-0 right-0 opacity-0 translate-y-40 pointer-events-none"
          }`}
        >
          {elements?.map((input) => (
            <DragItem key={input.key} {...input} onClick={onClick} />
          ))}
        </div>
      </div>
    );
  }
}

////////////////////////

function DragItem(props) {
  const { id, title, icon, type, onClick, isSection, isCustomInput, thumbnail, handlePreview, handleEdit, customId, children } = props;

  // DRAGGABLE
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: id,
    data: {
      key: type,
      title: title,
      isSidebar: true,
      isSection: isSection,
      thumbnail: thumbnail,
      customId: customId,
      children: children,
    },
  });

  return (
    <div
      ref={setNodeRef}
      className="flex w-full my-2 p-3 bg-gray-200 justify-between items-center rounded-md overflow-hidden cursor-pointer transition-all duration-300 hover:bg-gray-300"
      onClick={() => onClick({ type, id, customId, children })}
      {...listeners}
      {...attributes}
    >
      <span className="text-[14px]">{title}</span>
      {icon}
      {isSection && thumbnail && (
        <EyeIcon className="w-7 h-7 text-black p-1.5 aspect-square border-gray-300 border rounded transition-all duration-500 hover:text-white hover:bg-primary-500" onClick={handlePreview} />
      )}
      {isCustomInput && (
        <PencilSquareIcon className="w-7 h-7 text-black p-1.5 aspect-square border-gray-300 border rounded transition-all duration-500 hover:text-white hover:bg-primary-500" onClick={handleEdit} />
      )}
    </div>
  );
}
