import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Select from "app/components/atoms/inputs/select";
import Text from "app/components/atoms/inputs/text";
import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";
import { setOrder, setWhere } from "app/stores/files";
import { fileTypeLabels, fileTypes } from "app/utils/content";
import { isArray } from "app/utils/validators/dataValidator";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function MediaFilters(props) {
  const { show, modal, formats, loading } = props;

  // STORE
  const dispatch = useDispatch();
  const { fileCategories, project } = useSelector((state) => state.project);
  const { where, orderBy } = useSelector((state) => state.file);

  // REF
  const timeoutRef = useRef(null);

  // STATE
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState(undefined);
  const [typeOptions, setTypeOptions] = useState([]);

  const ordering = [
    { value: "createdAt_desc", label: i18n("label.most_recent") },
    { value: "createdAt_asc", label: i18n("label.older") },
    { value: "title_asc", label: `${i18n("label.alphabetically")} (A-Z)` },
    { value: "title_desc", label: `${i18n("label.alphabetically")} (Z-A)` },
    { value: "size_desc", label: `${i18n("label.size")} (DESC)` },
    { value: "size_asc", label: `${i18n("label.size")} (ASC)` },
  ];

  useEffect(() => {
    buildFilters();
  }, [project, where, orderBy]);

  useEffect(() => {
    if (fileTypes?.length) {
      let options = fileTypes;
      if (formats) {
        if (typeof formats === "string") options = options.filter((e) => e.value === formats);
        else if (isArray(formats)) options = options.filter((e) => formats.includes(e.value));
      } else {
        options = options.filter((e) => e.value !== "AVATAR");
      }
      setTypeOptions([...options]);
    }
  }, [fileTypes, formats]);

  // APPLYING TIMER TO SEARCH AFTER STOP TYPING
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      let temp = { ...where };
      if (search) {
        temp.OR = [{ title: { contains: search, mode: "insensitive" } }];
        dispatch(setWhere({ ...temp }));
      } else if (typeof search === "string" && temp.OR) {
        delete temp.OR;
        dispatch(setWhere({ ...temp }));
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [search]);

  const buildFilters = () => {
    const temp = { ...filters };

    // SET PROJECT (IMPORTANT)
    if (project) temp.project = { id: project.id };

    // SET CATEGORIES
    if (where) {
      const ids = where?.categories?.some?.OR?.map((e) => e.id);
      if (ids?.length) temp.categories = fileCategories.filter((e) => ids.includes(e.value));
      else temp.categories = undefined;
    }

    // SET TYPE
    if (where.type) temp.type = fileTypes.find((e) => e.value === where.type);
    else temp.type = undefined;

    // SET ORDER
    if (orderBy) {
      const keys = Object.keys(orderBy);
      temp.orderBy = ordering.find((e) => e.value === `${keys[0]}_${orderBy[keys[0]]}`);
    }

    // UPDATE IF DATA
    if (Object.keys(temp).length) {
      setFilters({ ...temp });
    }
  };

  const onChangeSearch = (ev) => {
    setSearch(ev);
  };
  const onChangeOrderBy = (ev) => {
    if (!ev || !ev.value) return null;
    const obj = ev.value.split("_");
    dispatch(setOrder({ [obj[0]]: obj[1] }));
  };
  const onChangeCategory = (ev) => {
    let temp = { ...where };
    if (ev?.length) temp.categories = { some: { OR: ev.map((cat) => ({ id: cat.value })) } };
    else temp.categories = undefined;
    dispatch(setWhere({ ...temp }));
  };
  const onChangeType = (ev) => {
    let temp = { ...where };
    if (ev) temp.type = ev.value;
    else temp.type = undefined;
    dispatch(setWhere({ ...temp }));
  };

  // HIDE & SEEK FILTERS
  let styles = {
    div: "filters-media z-10 flex flex-col pt-3 w-full border-b border-gray-200 justify-between transition-all ease-in-out duration-300 transform origin-top lg:pt-0 lg:flex-row lg:items-center ",
    shadow: "main-transition ",
  };
  if (modal) styles.div += " absolute top-0 px-6 left-0 w-full bg-white ";
  else styles.div += " relative ";

  if (show) {
    styles.div += "scale-y-100";
    styles.shadow += "h-12";
  } else {
    styles.div += "scale-y-0 h-0";
    styles.shadow += "";
  }

  return (
    <>
      <div className={styles.div}>
        <div className="flex items-center search-input">
          <MagnifyingGlassIcon className="h-4 text-gray-400" />
          <Text placeholder={i18n("label.search_by_name")} value={search} onChange={onChangeSearch} />
        </div>
        <div className="flex items-center gap-2 py-3">
          <Loading active={loading} className="mr-2" />
          <Select placeholder={i18n("label.categories")} value={filters?.categories} options={fileCategories} onChange={onChangeCategory} multiple clearable />
          <Select placeholder={i18n("label.type")} value={filters?.type} options={typeOptions} onChange={onChangeType} clearable={!formats} />
          <Select placeholder={i18n("label.order_by")} value={filters?.orderBy} options={ordering} onChange={onChangeOrderBy} />
        </div>
      </div>
      {modal && <div className={styles.shadow} />}
    </>
  );
}
