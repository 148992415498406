import IconPlus from "app/components/atoms/icons/iconPlus";
import Loading from "app/components/atoms/loading";
import ModalTemplate from "app/components/molecules/modals/modalTemplate";
import { i18n } from "app/i18n";
import { useCreateTemplateMutation, useGetTemplatesQuery } from "app/stores/builder";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TemplatesEdit from "./Edit";

export default function Templates() {
  // ROUTER
  const navigate = useNavigate();
  let { project, id } = useParams();

  // STATE
  const [modal, setModal] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  // QUERY
  const {
    data: templates,
    isFetching,
    isLoading: isGetting,
    isUninitialized,
    refetch: reloadList,
  } = useGetTemplatesQuery(
    { where: { project: { id: project } }, orderBy: { title: "asc" } },
    {
      skip: !project,
    }
  );

  // MUTATION
  const [createTemplate, { isLoading: isCreating }] = useCreateTemplateMutation();

  // VARS
  const isLoading = isCreating || isFetching || isGetting;

  // FUNCTIONS
  const onCreate = () => {
    setModal({
      isOpen: true,
      onSubmit: (data) => {
        createTemplate({ ...data, project }).then((res) => {
          if (res?.data) {
            reloadList();
            setModal(null);
            navigate(`/project/${project}/builder/templates/${res.data.id}`);
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };
  const onDuplicate = (structure) => {
    let sections = undefined;
    if (structure?.length) {
      sections = { create: [] };
      for (let i = 0; i < structure.length; i++) {
        sections.create.push({ id: structure[i].section.id, order: structure[i].order });
      }
    }
    setModal({
      isOpen: true,
      onSubmit: (data) => {
        createTemplate({ ...data, project, sections }).then((res) => {
          if (res?.data) {
            reloadList();
            setModal(null);
            navigate(`/project/${project}/builder/templates/${res.data.id}`);
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };
  const isCurrent = (_id) => {
    const tabNotActive = "after:w-0";
    const tabActive = "after:w-2";
    const tabClass = "border-b relative transition-all duration-500 after:h-full after:absolute after:bg-primary-500 after:right-0 after:top-0 after:transition-all after:duration-500";
    return _id === id ? `${tabClass} ${tabActive}` : `${tabClass} ${tabNotActive}`;
  };
  // const onExport = () => {
  //   // TO-DO
  // };
  // const onImport = () => {
  //   // TO-DO
  // };

  return (
    <>
      <div className="flex flex-1 bg-white max-h-full size-builder">
        {/* SIDEBAR LIST TEMPLATES */}
        <div className={`border-r flex flex-col relative flex-1 max-h-full ${collapsed ? "max-w-0" : "max-w-[180px] lg:max-w-[240px]"} transition-all duration-500 ease-in-out`}>
          <button
            type="button"
            onClick={toggleMenu}
            className="block xl:hidden absolute -right-3 top-0 bottom-0 m-auto w-fit py-0.5 px-4 h-fit rotate-90 origin-right text-sm border z-10 font-xs rounded-t-md bg-primary-900 text-white hover:bg-primary-500 transition-all duration-300 ease-in-out"
          >
            {collapsed ? i18n("button.show") : i18n("button.hide")}
          </button>
          <div className={`p-6 pr-2 pb-8 flex justify-between items-center transition-all duration-500 ease-in-out ${collapsed ? "w-0 pr-0 pl-0 overflow-hidden" : "w-full"}`}>
            <h1 className="font-bold text-text text-lg">{i18n("templates.title")}</h1>
            {/* <Dropdown
              options={[
                { title: i18n("label.import"), icon: "import", onClick: onImport },
                { title: i18n("label.export"), icon: "export", onClick: onExport },
              ]}
            /> */}
          </div>
          <div className="flex-1 overflow-hidden">
            {isLoading && <Loading active className="h-full flex items-center justify-center" />}
            <div className="max-h-full overflow-auto">
              {templates?.length ? (
                <ul className="border-t">
                  {templates.map((template) => (
                    <li className={`${isCurrent(template.id)}`} key={template.id}>
                      <Link to={`/project/${project}/builder/templates/${template.id}`}>
                        <div className="block px-6 py-3 text-sm text-ellipsis line-clamp-2 overflow-hidden">{template.title}</div>
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="h-full px-6 py-3 flex items-center">
                  <span className="text-md text-slate-500 italic">{i18n("templates.createText")}</span>
                </div>
              )}
            </div>
          </div>
          <div className={`flex justify-center py-6 overflow-hidden transition-all duration-500 ease-in-out ${collapsed ? "px-0" : "w-auto px-4 lg:px-6"}`}>
            <button type="button" disabled={isLoading} onClick={onCreate} className="btn-primary text-xs lg:text-md">
              <IconPlus />
              {i18n("templates.button")}
            </button>
          </div>
        </div>

        {/* BUILDER */}
        <div className="flex flex-1 bg-gray-50">
          {templates && !isUninitialized ? (
            !!templates.find((e) => e.id === id) ? (
              <TemplatesEdit reloadList={reloadList} onDuplicate={onDuplicate} />
            ) : (
              <div className="h-full w-full relative flex items-center justify-center">
                <span className="text-xl md:text-2xl xl:text-4xl text-slate-300 italic px-8">{i18n("templates.selectText")}</span>
              </div>
            )
          ) : (
            <Loading active className="h-full flex items-center justify-center" />
          )}
        </div>
      </div>

      <ModalTemplate {...modal} />
    </>
  );
}
