import { gql } from "@apollo/client";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { createSlice } from "@reduxjs/toolkit";
import { serverAuth } from "app/utils/server";
import { addItemToBuilderHistory } from "app/utils/visual";

const initialState = {
  // HISTORY
  active: 0,
  history: null,
  inputs: [],
};

export const builderSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    setHistory: (state, { payload }) => {
      state.history = payload;
    },
    setActiveHistory: (state, { payload }) => {
      state.active = payload;
    },
    setInputs: (state, { payload }) => {
      state.inputs = payload || [];
    },
  },
});

export const builderApi = createApi({
  reducerPath: "builderApi",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    // SECTIONS
    getSections: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query sections($take: Int, $skip: Int, $where: SectionWhere, $orderBy: SectionOrderBy) {
            sections(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              key
              title
              thumbnail {
                href
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.sections,
    }),
    getSection: builder.query({
      query: ({ id, project }) => ({
        document: gql`
          query section($id: ID!, $project: ID!) {
            section(id: $id, project: $project) {
              id
              key
              title
              inUse
              structure
              thumbnail {
                id
                href
              }
              templates {
                template {
                  title
                }
              }
            }
          }
        `,
        variables: {
          id,
          project,
        },
      }),
      providesTags: (result, error) => [{ type: "Section" }],
      transformResponse: (response) => response.section,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          // START DATA FROM CURRENT PROJECT
          addItemToBuilderHistory([], undefined, undefined, data?.structure, builderSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createSection: builder.mutation({
      query: ({ title, key, thumbnail, structure, project }) => ({
        document: gql`
          mutation createSection($title: String!, $key: String!, $project: GenericConnect!, $thumbnail: GenericConnect, $structure: Json) {
            createSection(title: $title, key: $key, project: $project, thumbnail: $thumbnail, structure: $structure) {
              id
            }
          }
        `,
        variables: {
          key,
          title,
          thumbnail,
          structure,
          project: project ? { connect: { id: project } } : undefined,
        },
      }),
      transformResponse: (response) => response.createSection,
    }),
    updateSection: builder.mutation({
      query: ({ id, title, key, structure, thumbnail }) => ({
        document: gql`
          mutation updateSection($id: String!, $title: String, $key: String, $structure: Json, $thumbnail: GenericConnect) {
            updateSection(id: $id, title: $title, key: $key, structure: $structure, thumbnail: $thumbnail) {
              id
              key
              title
              structure
              thumbnail {
                id
                href
              }
            }
          }
        `,
        variables: {
          id,
          key,
          title,
          structure,
          thumbnail,
        },
      }),
      transformResponse: (response) => response.updateSection,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { builder } = getState();

          // UPDATE DATA FROM CURRENT PROJECT
          if (!params?.history) addItemToBuilderHistory(builder?.history, builder?.active, !!params?.history, data?.structure, builderSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteSection: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteSection($id: String!) {
            deleteSection(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteSection,
    }),

    // TEMPLATES
    getTemplates: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query templates($take: Int, $skip: Int, $where: TemplateWhere, $orderBy: TemplateOrderBy) {
            templates(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              title
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.templates,
    }),

    getTemplate: builder.query({
      query: ({ id, project }) => ({
        document: gql`
          query template($id: String!, $project: ID!) {
            template(id: $id, project: $project) {
              id
              title
              inUse
              posts {
                id
                translations {
                  id
                  title
                  published
                  language {
                    slug
                  }
                }
              }
              sections {
                id
                order
                section {
                  id
                  title
                  thumbnail {
                    id
                    href
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
          project,
        },
      }),
      providesTags: (result, error) => [{ type: "Template" }],
      transformResponse: (response) => response.template,
    }),

    createTemplate: builder.mutation({
      query: ({ title, project, sections }) => ({
        document: gql`
          mutation createTemplate($title: String!, $project: GenericConnect!, $sections: InputSetSections) {
            createTemplate(title: $title, project: $project, sections: $sections) {
              id
            }
          }
        `,
        variables: {
          title,
          sections,
          project: project ? { connect: { id: project } } : undefined,
        },
      }),
      transformResponse: (response) => response.createTemplate,
    }),

    updateTemplate: builder.mutation({
      query: ({ id, title, sections }) => ({
        document: gql`
          mutation updateTemplate($id: String!, $title: String, $sections: InputSetSections) {
            updateTemplate(id: $id, title: $title, sections: $sections) {
              id
              sections {
                id
                order
                section {
                  id
                  title
                  thumbnail {
                    id
                    href
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
          title,
          sections,
        },
      }),
      transformResponse: (response) => response.updateTemplate,
    }),

    deleteTemplate: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteTemplate($id: String!) {
            deleteTemplate(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteTemplate,
    }),

    // POST TYPES
    getPostTypes: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query postTypes($take: Int, $skip: Int, $where: PostTypeWhere, $orderBy: PostTypeOrderBy) {
            postTypes(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              key
              title
              webservice
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.postTypes,
    }),

    getPostType: builder.query({
      query: ({ id, project }) => ({
        document: gql`
          query postType($id: String!, $project: ID!) {
            postType(id: $id, project: $project) {
              id
              key
              title
              structure
              hierarchy
              singlePage
              webservice
              count(where: { translations: { some: { published: true } } })
              templates {
                id
              }
              slugs {
                value
                language {
                  slug
                }
              }
              categories {
                id
                key
                icon
                title
                multi
                image
                hierarchy
              }
            }
          }
        `,
        variables: {
          id,
          project,
        },
      }),
      providesTags: (result, error) => [{ type: "PostType" }],
      transformResponse: (response) => response.postType,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          // START DATA FROM CURRENT PROJECT
          addItemToBuilderHistory([], undefined, undefined, data?.structure, builderSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),

    createPostType: builder.mutation({
      query: ({ key, slugs, title, templates, categories, singlePage, hierarchy, structure, webservice, project }) => ({
        document: gql`
          mutation createPostType(
            $title: String!
            $key: String!
            $templates: [String!]
            $slugs: [PostTypeSlugsInput!]!
            $categories: [PostTypeCategoryInput!]
            $singlePage: Boolean!
            $hierarchy: Boolean!
            $webservice: Boolean!
            $project: GenericConnect!
            $structure: Json
          ) {
            createPostType(
              title: $title
              key: $key
              slugs: $slugs
              templates: $templates
              project: $project
              categories: $categories
              singlePage: $singlePage
              hierarchy: $hierarchy
              webservice: $webservice
              structure: $structure
            ) {
              id
              key
              title
            }
          }
        `,
        variables: {
          key,
          slugs,
          title,
          templates,
          categories,
          singlePage,
          hierarchy,
          structure,
          webservice,
          project: project ? { connect: { id: project } } : undefined,
        },
      }),
      transformResponse: (response) => {
        return response.createPostType;
      },
    }),

    updatePostType: builder.mutation({
      query: ({ id, title, key, slugs, templates, singlePage, categories, hierarchy, webservice, structure }) => ({
        document: gql`
          mutation updatePostType(
            $id: String!
            $title: String
            $key: String
            $templates: [String!]
            $slugs: [PostTypeSlugsInput!]
            $categories: [PostTypeCategoryInput!]
            $singlePage: Boolean
            $hierarchy: Boolean
            $webservice: Boolean
            $structure: Json
          ) {
            updatePostType(
              id: $id
              title: $title
              key: $key
              slugs: $slugs
              templates: $templates
              categories: $categories
              singlePage: $singlePage
              hierarchy: $hierarchy
              webservice: $webservice
              structure: $structure
            ) {
              id
              key
              title
              hierarchy
              webservice
              structure
              singlePage
              templates {
                id
              }
              slugs {
                value
                language {
                  slug
                }
              }
              categories {
                id
                key
                icon
                title
                multi
                image
                hierarchy
              }
            }
          }
        `,
        variables: {
          id,
          key,
          title,
          slugs,
          templates,
          singlePage,
          hierarchy,
          webservice,
          categories,
          structure,
        },
      }),
      transformResponse: (response) => response.updatePostType,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { builder } = getState();

          // UPDATE DATA FROM CURRENT PROJECT
          if (!params?.history) addItemToBuilderHistory(builder?.history, builder?.active, !!params?.history, data?.structure, builderSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),

    deletePostType: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deletePostType($id: String!) {
            deletePostType(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deletePostType,
    }),

    // CONTENT TYPES
    getContentTypes: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query contentTypes($take: Int, $skip: Int, $where: ContentTypeWhere, $orderBy: ContentTypeOrderBy) {
            contentTypes(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              title
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.contentTypes,
    }),

    getContentType: builder.query({
      query: ({ id, project }) => ({
        document: gql`
          query contentType($id: String!, $project: ID!) {
            contentType(id: $id, project: $project) {
              id
              key
              title
              structure
            }
          }
        `,
        variables: {
          id,
          project,
        },
      }),
      providesTags: (result, error) => [{ type: "ContentType" }],
      transformResponse: (response) => response.contentType,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          // START DATA FROM CURRENT PROJECT
          addItemToBuilderHistory([], undefined, undefined, data?.structure, builderSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),

    createContentType: builder.mutation({
      query: ({ title, key, project, structure }) => ({
        document: gql`
          mutation createContentType($title: String!, $key: String!, $project: GenericConnect!, $structure: Json) {
            createContentType(title: $title, key: $key, project: $project, structure: $structure) {
              id
              key
              title
              structure
            }
          }
        `,
        variables: {
          title,
          key,
          structure,
          project: project ? { connect: { id: project } } : undefined,
        },
      }),
      transformResponse: (response) => response.createContentType,
    }),

    updateContentType: builder.mutation({
      query: ({ id, title, key, structure }) => ({
        document: gql`
          mutation updateContentType($id: String!, $title: String, $key: String, $structure: Json) {
            updateContentType(id: $id, title: $title, key: $key, structure: $structure) {
              id
              key
              title
              structure
            }
          }
        `,
        variables: {
          id,
          key,
          title,
          structure,
        },
      }),
      transformResponse: (response) => response.updateContentType,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { builder } = getState();

          // UPDATE DATA FROM CURRENT PROJECT
          if (!params?.history) addItemToBuilderHistory(builder?.history, builder?.active, !!params?.history, data?.structure, builderSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),

    deleteContentType: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation deleteContentType($id: String!) {
            deleteContentType(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.deleteContentType,
    }),
  }),
});

export const { setActiveHistory, setHistory, setSection } = builderSlice.actions;

export const {
  // SECTIONS
  useGetSectionsQuery,
  useGetSectionQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
  //TEMPLATE
  useGetTemplatesQuery,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  // POST TYPES
  useGetPostTypesQuery,
  useGetPostTypeQuery,
  useCreatePostTypeMutation,
  useUpdatePostTypeMutation,
  useDeletePostTypeMutation,
  // CONTENT TYPES
  useGetContentTypesQuery,
  useGetContentTypeQuery,
  useCreateContentTypeMutation,
  useUpdateContentTypeMutation,
  useDeleteContentTypeMutation,
} = builderApi;

export default builderSlice.reducer;
