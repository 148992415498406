import { useEffect } from "react";
import { Navigate, Route, RouterProvider, Routes, useLocation } from "react-router-dom";

// MIDDLEWARE
import AuthMiddleware from "app/components/middleware/auth";
import ProjectMiddleware from "app/components/middleware/project";
import WelcomeMiddleware from "app/components/middleware/welcome";

// CONTAINERS
import { setProject } from "app/stores/project";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../Dashboard";
import Intro from "../Intro";
import Login from "../Login";
import NewAccount from "../NewAccount";
import Project from "../Project";
import RequestPassword from "../RequestPassword";
import ResetPassword from "../ResetPassword";
import ValidateAccount from "../ValidateAccount";
import Welcome from "../Welcome";

export default function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { project } = useSelector((store) => store.project);

  useEffect(() => {
    if (!pathname.startsWith("/project/") && project) dispatch(setProject(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, project]);

  return (
    <Routes>
      {/* PROJECT */}
      <Route path="/project/:project/*" element={<ProjectMiddleware />}>
        <Route path="*" element={<Project />} />
      </Route>

      {/* DASHBOARD */}
      <Route path="/dashboard/*" element={<AuthMiddleware />}>
        <Route path="*" element={<Dashboard />} />
      </Route>

      {/* INTRO */}
      <Route element={<Intro />}>
        <Route path="login" element={<Login />} />
        <Route path="request-password" element={<RequestPassword />} />
        <Route path="validate-account">
          <Route path=":token" element={<ValidateAccount />} />
          <Route index element={<ValidateAccount />} />
        </Route>
        <Route path="new-password">
          <Route path=":token" element={<ResetPassword />} />
          <Route index element={<ResetPassword />} />
        </Route>
        <Route path="new-account">
          <Route path=":token" element={<NewAccount />} />
          <Route index element={<NewAccount />} />
        </Route>
        <Route index element={<Navigate to="login" />} />
      </Route>

      {/* WELCOME */}
      <Route element={<WelcomeMiddleware />}>
        <Route path="/welcome" element={<Welcome />} />
      </Route>
    </Routes>
  );
}
