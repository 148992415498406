import { useEffect, useState } from "react";
import validator from "validator";
import { i18n } from "app/i18n";

// Atoms
import { Content, Footer, Header, Struture, Tabs } from "../../../atoms/modal";

// Organisms
import Form from "app/components/organisms/form";

// Helpers
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import { components, tabs } from "./constants";

// Store
import { useGetTemplatesQuery } from "app/stores/builders";
import { useGetLanguagesQuery } from "app/stores/languages";
import { useSelector } from "react-redux";

export default function ModalPostType(props) {
  const { isOpen = false, postType, buttonText = i18n("label.create_postType"), onSubmit, isLoading, onClose = () => {} } = props;

  // STATES
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(components);
  const [value, setValue] = useState({ singlePage: true });

  // STORE
  const { user } = useSelector((store) => store.auth);
  const { project } = useSelector((store) => store.project);

  // REQUEST
  const { data: languages } = useGetLanguagesQuery({});
  const { data: templates, refetch } = useGetTemplatesQuery(
    { where: { project: { id: project?.id } }, orderBy: { title: "asc" } },
    {
      skip: !project?.id,
    }
  );

  // VARS
  const inputKey = value && value.key;

  useEffect(() => {
    if (!isOpen) {
      setValue({ singlePage: true });
      setTabActive(0);
      setStructure(cleanStructure(components));
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // BUILD STRUCTURE
  useEffect(() => {
    if (isOpen) {
      const tempStructure = [...components];

      // SLUGS
      if (languages?.length) {
        const index = tempStructure.findIndex((item) => item.key === "slugs");
        if (index !== -1)
          tempStructure[index].children = languages.map((item) => ({
            title: item.name,
            key: item.slug,
            type: "Text",
            rules: [{ method: validator.isEmpty, validWhen: false, message: i18n("input.required_field") }],
          }));
      }

      // TEMPLATES
      if (templates?.length) {
        const index = tempStructure.findIndex((item) => item.key === "templates");
        if (index !== -1) tempStructure[index].options = templates.map((item) => ({ value: item.id, label: item.title }));
      }

      // KEY

      const index = tempStructure.findIndex((item) => item.key === "key");
      if (index !== -1) {
        if (postType) tempStructure[index].rules[1].args.id = postType.id;
        // PROJECT (REQUIRED)
        if (project?.id) tempStructure[index].rules[1].args.project = project.id;
      }

      // DISABLE INPUTS
      if (postType?.key === "pages") {
        let index = -1;
        // SLUGS
        index = tempStructure.findIndex((item) => item.key === "slugs");
        /*         if (index !== -1) tempStructure[index] = { key: "slugs", tab: 1, component: "message", text: i18n("label.postType_noAvailable"), align: "text-center" };
         */ if (index !== -1) tempStructure.splice(index, 1);

        // KEY
        index = tempStructure.findIndex((item) => item.key === "key");
        if (index !== -1) tempStructure[index].disabled = true;
        // TITLE
        index = tempStructure.findIndex((item) => item.key === "title");
        if (index !== -1) tempStructure[index].disabled = true;
        // SINGLE PAGE
        index = tempStructure.findIndex((item) => item.key === "singlePage");
        if (index !== -1) tempStructure.splice(index, 1);
        // WEBSERVICE
        index = tempStructure.findIndex((item) => item.key === "webservice");
        if (index !== -1) tempStructure.splice(index, 1);
        // HIERARCHY
        index = tempStructure.findIndex((item) => item.key === "hierarchy");
        if (index !== -1) tempStructure.splice(index, 1);
      }
      setStructure([...tempStructure]);
    }
  }, [isOpen, postType, languages, templates, project, user]);

  // FILL FORM
  useEffect(() => {
    if (postType) {
      const tempValue = { ...postType };
      // SLUGS
      if (languages?.length && tempValue?.slugs?.length) {
        const slugs = {};
        for (let i = 0; i < languages.length; i++) {
          const index = tempValue.slugs.findIndex((item) => item.language.slug === languages[i].slug);
          slugs[languages[i].slug] = index !== -1 ? tempValue.slugs[index].value : tempValue.key;
        }
        tempValue.slugs = slugs;
      }
      // TEMPLATES
      if (templates?.length && tempValue?.templates?.length) {
        const temps = [];
        for (let i = 0; i < tempValue?.templates.length; i++) {
          const template = templates.find((item) => item.id === tempValue.templates[i].id);

          if (template) temps.push({ value: template.id, label: template.title });
        }
        tempValue.templates = temps;
      }

      setValue({ ...tempValue });
    }
  }, [postType, languages, templates, project, user]);

  // AUTO FILL IF EMPTY SLUG
  useEffect(() => {
    if (languages?.length && inputKey) {
      if (!value.slugs) {
        const tempValue = { ...value, slugs: {} };
        for (let i = 0; i < languages.length; i++) {
          tempValue.slugs[languages[i].slug] = tempValue.key;
        }
        setValue({ ...tempValue });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputKey, languages]);

  // WHEN SUBMIT
  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      let isValid = validation.isValid;
      let tempStructure = [...structure];

      // VALIDATE IF HASNT DUPLICATED KEYS
      if (value?.categories?.length) {
        function hasDuplicatedKeys(arr) {
          const seen = [];
          const filtered = arr.filter((e) => {
            if (!seen.find((o) => o.key === e.key)) {
              seen.push(e);
              return false;
            }
            return true;
          });
          return !!filtered.length;
        }
        if (hasDuplicatedKeys(value.categories)) {
          isValid = false;
          const index = tempStructure.findIndex((e) => e.key === "categories");
          if (index !== -1) {
            tempStructure[index].error = true;
            tempStructure[index].errorList = i18n("input.duplicated_key");
          }
        }
      }

      setStructure([...tempStructure]);

      if (isValid) {
        let fields = {};

        // PAGES VALIDATION
        const isMainPagePostType = postType?.key === "pages";

        // FIELDS FOR ALL TYPES
        fields = {
          hierarchy: typeof value.hierarchy !== "undefined" ? !!value.hierarchy : false,
          webservice: typeof value.webservice !== "undefined" ? !!value.webservice : false,
          templates: value?.templates?.length ? value.templates.map((item) => item.value) : undefined,
          categories: value?.categories?.length
            ? value.categories.map((item) => ({
                id: item.id,
                key: item.key,
                title: item.title,
                multi: !!item.multi,
                image: !!item.image,
                required: !!item.required,
                hierarchy: !!item.hierarchy,
              }))
            : undefined,
        };

        // FIELDS FOR WHEN IS NO PAGES POST TYPE
        if (!isMainPagePostType) {
          let slugs = [];
          if (!value.slugs) slugs = languages?.length ? languages.map((item) => ({ language: item.slug, slug: value.key })) : undefined;
          else slugs = Object.keys(value.slugs).map((key) => ({ language: key, slug: value.slugs[key] }));

          fields = {
            ...fields,
            slugs,
            key: value.key,
            title: value.title,
            singlePage: typeof value.singlePage !== "undefined" ? !!value.singlePage : true,
          };
        } else {
          fields = {
            ...fields,
            slugs: undefined,
            key: postType.key,
            title: postType.title,
            singlePage: postType.singlePage,
          };
        }
        onSubmit({ ...fields });
      } else {
        const tabWithError = structure.find((item) => item.error).tab;
        setTabActive(tabWithError);
      }
    });
  };

  const filteredTabs = tabs
    .map((tab, index) => ({ ...tab, originalIndex: index }))
    .filter((tab) => {
      // Check if the tab has content to show
      const hasContent = structure.some((item) => item.tab === tab.originalIndex);
      return hasContent;
    });

  return (
    <Struture size={tabActive === 2 ? "md" : ""} isOpen={isOpen} onClose={onClose}>
      <Header title={postType ? i18n("label.edit_postType") : i18n("label.create_postType")} onClose={onClose} />
      <Tabs tabs={filteredTabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        {filteredTabs.map((tab, index) => (
          <div key={index}>
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} tab={tab.originalIndex} tabMode />
          </div>
        ))}
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
