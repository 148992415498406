import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { apolloClient, getCookie, serverAuth } from "app/utils/server";
import { addFilesToMediaList, editFileInMediaList, removeFilesFromMediaList, updateListOfFileCategories } from "app/utils/visual";
import { projectSlice } from "../project";
import { updateMediaInfo } from "./functions";

const initialState = {
  files: null,
  filesSelected: null,
  loading: false,

  take: 12,
  page: 0,
  skip: 0,
  where: {},
  orderBy: { createdAt: "desc" },

  views: [
    { name: "List", label: "list", icon: "list" },
    { name: "Grid", label: "grid", icon: "grid" },
  ],
  mediaView: "grid",
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setTake: (state, { payload }) => {
      state.take = payload;
    },
    setSkip: (state, { payload }) => {
      state.skip = payload;
    },
    setWhere: (state, { payload }) => {
      state.skip = 0;
      state.where = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (payload ? payload - 1 : 0) * state.take;
    },
    setOrder: (state, { payload }) => {
      state.skip = 0;
      state.orderBy = payload;
    },
    setFiles: (state, { payload }) => {
      state.files = payload;
    },
    setFilesSelected: (state, { payload }) => {
      state.filesSelected = payload;
    },
    setView: (state, { payload }) => {
      state.mediaView = payload;
    },
  },
});

export const filesApi = createApi({
  reducerPath: "fileAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getMedia: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query files($take: Int, $skip: Int, $where: FileWhere, $orderBy: FileOrderBy) {
            files(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              alt
              path
              type
              size
              title
              extension
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.files,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { file } = getState();
          if (params.skip) dispatch(filesSlice.actions.setFiles([...file.files, ...data]));
          else dispatch(filesSlice.actions.setFiles([...data]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getMediaDetails: builder.query({
      query: ({ current }) => ({
        document: gql`
          query files($where: FileWhere) {
            files(where: $where) {
              id
              title
              path
              alt
              type
              size
              inUse
              width
              height
              extension
              orientation
              createdAt
              uploadBy {
                name
              }
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
            }
          }
        `,
        variables: {
          where: current?.length ? { id: { in: current } } : { id: { in: [] } },
        },
      }),
      transformResponse: (response) => response.files,
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(filesSlice.actions.setFilesSelected([...data]));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getMediaTotal: builder.query({
      query: ({ where }) => ({
        document: gql`
          query filesTotal($where: FileWhere) {
            filesTotal(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.filesTotal,
    }),
    // uploadMedia: builder.mutation({
    //   query: ({ files, forceType, project }) => ({
    //     document: gql`
    //       mutation createMedia($files: [Upload!]!, $forceType: String, $project: String) {
    //         createMedia(files: $files, project: $project, forceType: $forceType) {
    //           id
    //           title
    //           path
    //           type
    //           size
    //           extension
    //         }
    //       }
    //     `,
    //     variables: {
    //       files,
    //       project,
    //       forceType,
    //     },
    //   }),
    //   transformResponse: (response) => response.createMedia,
    //   async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       const { file } = getState();

    //       // UPDATE LIST
    //       if (data?.length) addFilesToMediaList(file.files, data, false, filesSlice, dispatch);
    //     } catch (err) {
    //       console.error(err);
    //     }
    //   },
    // }),
    createExternalMedia: builder.mutation({
      query: ({ title, url, project }) => ({
        document: gql`
          mutation createExternalMedia($project: GenericConnect!, $title: String!, $url: String!) {
            createExternalMedia(project: $project, title: $title, url: $url) {
              id
              title
              path
              type
              size
              extension
            }
          }
        `,
        variables: {
          url,
          title,
          project: project ? { connect: { id: project } } : undefined,
        },
      }),
      transformResponse: (response) => response.createExternalMedia,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { file } = getState();

          // UPDATE LIST
          addFilesToMediaList(file.files, data, false, filesSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updateMedia: builder.mutation({
      query: ({ id, title, alt, categories }) => ({
        document: gql`
          mutation updateMedia($id: String!, $title: String, $alt: String, $categories: [MediaCategoriesInput!]) {
            updateMedia(id: $id, title: $title, alt: $alt, categories: $categories) {
              id
              alt
              type
              title
              extension
              categories {
                id
                translations {
                  value
                  language {
                    slug
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
          alt,
          title,
          categories,
        },
      }),
      transformResponse: (response) => response.updateMedia,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { file, project } = getState();
          // UPDATE SELECTED
          const updateListSelected = updateMediaInfo(file.filesSelected, data);
          dispatch(filesSlice.actions.setFilesSelected(updateListSelected));
          // UPDATE LIST
          editFileInMediaList(file.files, data, filesSlice, dispatch);
          // UPDATE FILE CATEGORIES IF NECESSARY
          updateListOfFileCategories(project?.fileCategories, data?.categories, project.language, projectSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteMedia: builder.mutation({
      query: ({ ids, project }) => ({
        document: gql`
          mutation deleteMedia($ids: [String!]!, $project: String!) {
            deleteMedia(ids: $ids, project: $project) {
              id
            }
          }
        `,
        variables: {
          ids,
          project,
        },
      }),
      transformResponse: (response) => response.deleteMedia,
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { file } = getState();

          // UPDATE LIST
          removeFilesFromMediaList(file.files, data, filesSlice, dispatch);
          // UPDATE SELECTED
          dispatch(filesSlice.actions.setFilesSelected(null));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getOccurs: builder.query({
      query: ({ id }) => ({
        document: gql`
          query file($id: ID!) {
            file(id: $id) {
              id
              usedBy
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.file,
    }),
  }),
});

export const { setTake, setSkip, setWhere, setOrder, setPage, setFiles, setFilesSelected, setView } = filesSlice.actions;

export const {
  useGetMediaQuery,
  useGetMediaDetailsQuery,
  // useUploadMediaMutation,
  useCreateExternalMediaMutation,
  useUpdateMediaMutation,
  useDeleteMediaMutation,
  useGetMediaTotalQuery,
  useGetOccursQuery,
} = filesApi;

export default filesSlice.reducer;
