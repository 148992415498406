import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { GraphQLClient } from "graphql-request";
import { toast } from "react-toastify";
import { BACKEND_HOST } from "../config";
import { createUploadLink } from "apollo-upload-client";

export const apolloClient = new ApolloClient({
  link: createUploadLink({
    uri: BACKEND_HOST, // Altera conforme a tua API
  }),
  cache: new InMemoryCache(),
});

const client = new GraphQLClient(BACKEND_HOST);

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

// PASS TOKEN TO REQUEST
export function setHeader(accesstoken) {
  const token = getCookie("access_token");
  client.setHeader("Content-Type", "multipart/form-data");
  client.setHeader("Authorization", accesstoken ? accesstoken : token);
  return client;
}

// READ ERRORS
export const showServerError = (res) => {
  if (res.error) toast(readError(res.error), { type: "error", hideProgressBar: true, autoClose: 3000, pauseOnHover: true, position: "bottom-right" });
};
export const readError = (error) => {
  let message = error.message.split(":");
  if (message.length) return message[0];
  return error.name;
};

// READ SUCCESS
export const showServerSuccess = (text) => {
  toast(text, { type: "success", hideProgressBar: true, autoClose: 3000, pauseOnHover: true, position: "bottom-right" });
};

// SERVER FOR ENTRY REQUESTS
export const server = {
  url: BACKEND_HOST,
  prepareHeaders: (headers) => {
    return headers;
  },
};

// SERVER FOR DASHBOARD REQUESTS
export const serverAuth = {
  client: setHeader(),
};

// REQUEST
export const request = async (query, variables = {}) => {
  const url = BACKEND_HOST;
  const fetchConfig = {
    method: "POST",
    body: JSON.stringify({ query, variables }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(url, fetchConfig);
  const data = await response.json();
  return data;
};

// REQUEST WITH AUTH
export const requestAuth = async (query, variables = {}) => {
  const token = getCookie("access_token");
  const fetchConfig = {
    method: "POST",
    body: JSON.stringify({ query, variables }),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  const response = await fetch(BACKEND_HOST, fetchConfig);
  const data = await response.json();
  return data;
};

export const secureHost = () => {
  return BACKEND_HOST.toLowerCase().startsWith("https://");
};

// UPLOAD MUTATION
export const UPLOAD_MUTATION = gql`
  mutation createMedia($files: [Upload!]!, $forceType: String, $project: String) {
    createMedia(files: $files, project: $project, forceType: $forceType) {
      id
      title
      path
      type
      size
      extension
    }
  }
`;
