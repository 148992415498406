// IMPORTS
import { useEffect, useState } from "react";
import { i18n } from "app/i18n";
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import { Content, Footer, Header, Struture, Tabs } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import SortableTree from "app/components/organisms/buildernew";
import ModalConfirm from "../modalConfirm";
import { components, mainTab, structureTab } from "./constants";

export default function ModalComponentCustom(props) {
  const { isOpen = false, isCreate, isDrag, component, buttonText = i18n("label.edit_component"), validateKey, isLoading = false, onSubmit, onDelete, onClose = () => {} } = props;

  // STATE
  const [value, setValue] = useState(null);
  const [tabs, setTabs] = useState([mainTab]);
  const [tabActive, setTabActive] = useState(0);
  const [formStructure, setFormStructure] = useState(components);
  const [structure, setStructure] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(null);
  const [inicialStructure, setInitialStructure] = useState([]);

  // USEEFFECT TO HANDLE MODAL OPEN STATE
  useEffect(() => {
    if (isOpen) {
      const tempStructure = cleanStructure(components);

      if (isDrag) {
        // VALIDATE KEY IF EXISTS
        if (typeof validateKey !== "undefined") {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1)
            tempStructure[index].rules.push({
              method: validateKey,
              validWhen: true,
              message: i18n("input.key_already_in_use"),
              temporary: true,
            });
        }
      } else if (component && isCreate) {
        // CREATE MODE - REMOVE KEY FIELD
        setValue({ title: component.title });
        const index = tempStructure.findIndex((item) => item.key === "key");
        if (index !== -1) tempStructure.splice(index, 1);
      } else if (component) {
        // EDIT MODE
        if (typeof validateKey !== "undefined") {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1)
            tempStructure[index].rules.push({
              method: validateKey,
              validWhen: true,
              message: i18n("input.key_already_in_use"),
              temporary: true,
            });
        } else {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1) tempStructure.splice(index, 1);
        }
        setValue({ title: component.title, children: component.children });
        setStructure(component.children);
        setInitialStructure(component.children);
        setTabs([mainTab, structureTab]);
      }
      setFormStructure(tempStructure);
    } else {
      // RESET STATES ON CLOSE
      setValue(null);
      setTabs([mainTab]);
      setTabActive(0);
      setFormStructure(cleanStructure(components));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, component]);

  // UPDATE STRUCTURE STATE
  function onSave(structure, history) {
    setStructure(structure);
  }

  // HANDLE EDIT SUBMIT
  const onClickSubmitEdit = () => {
    setStructure([...structure]);
    onSubmit({ ...value, children: structure });
  };

  // HANDLE CREATE SUBMIT WITH VALIDATION
  const onClickSubmit = () => {
    validation(formStructure, value, (structure, validation) => {
      setFormStructure([...structure]);
      if (validation.isValid) onSubmit({ ...value });
    });
  };

  // HANDLE MODAL CLOSE
  const handleClose = () => {
    if (!isCreate && !isDrag && inicialStructure !== structure) {
      setModalConfirm({
        isOpen: true,
        type: "CONFIRM",
        title: i18n("label.unsaved_changes"),
        text: i18n("alert.unsaved_changes"),
        onConfirm: () => {
          setModalConfirm(null);
          onClose();
        },
        onClose: () => setModalConfirm(null),
      });
    } else {
      onClose();
    }
  };

  // HANDLE ENTER KEY PRESS TO SUBMIT
  const onClickEnter = (code) => {
    if (code === 13) onClickSubmit();
  };

  // RETURN TITLE BASED ON MODE
  const getTitle = () => {
    if (isDrag) return i18n("label.edit_custom_component");
    if (isCreate) return i18n("label.add_new_custom_component");
    return i18n("label.edit_custom_component");
  };

  return (
    <>
      <Struture isOpen={isOpen} onClose={handleClose} size="sm">
        <Header title={getTitle()} onClose={handleClose} />
        {tabs?.length > 1 && <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />}
        <Content tabs tab={tabActive} noPad>
          <div className="px-12 py-10">
            <Form value={value} onChange={setValue} structure={formStructure} disabled={isLoading} onKeyDown={onClickEnter} />
          </div>
          <div className="h-[500px]">
            <SortableTree isEditCustom title={value?.title} slug={value?.key} isLoading={isLoading} initialItems={structure ? [...structure] : []} onSave={onSave}></SortableTree>
          </div>
        </Content>
        <Footer
          loading={isLoading}
          cancel={{ text: i18n("button.close"), onClick: handleClose }}
          clean={{ text: i18n("button.delete"), onClick: onDelete }}
          submit={isCreate && component ? { text: buttonText, disabled: isLoading, onClick: onClickSubmit } : { text: buttonText, disabled: isLoading, onClick: onClickSubmitEdit }}
        />
      </Struture>
      <ModalConfirm {...modalConfirm} />
    </>
  );
}
