import { useDroppable } from "@dnd-kit/core";

export default function Droppable({ children, id, disabled, className = "" }) {
  const { setNodeRef } = useDroppable({ id, data: { item: "item" } });

  let style = "border border-dashed border-gray-300 rounded-lg flex-1 min-h-full w-full";
  if (id === "firstDrop") style += " h-full relative flex items-center justify-center";
  if (className) style += ` ${className}`;

  if (disabled) return children;

  return (
    <div ref={setNodeRef} className={style} aria-label="Droppable area">
      {children}
    </div>
  );
}
