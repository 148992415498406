import { Content, Footer, Header, Struture, Tabs } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { cleanStructure, getRandomString } from "app/utils/content";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { components, mainTab, structureTab } from "../modalComponentCustom/constants";
import elements from "app/components/organisms/builder/helpers/elements";
import ModalComponent from "../modalComponent";
import Droppable from "app/components/organisms/buildernew/components/droppable";
import SortableTreeItem from "app/components/organisms/buildernew/components/item";
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection, DragOverlay } from "@dnd-kit/core";
import {
  adjustTranslate,
  buildTree,
  dropAllowedInputs,
  dropAnimationConfig,
  flattenTree,
  getChildCount,
  getProjection,
  measuring,
  removeChildrenOf,
  removeItem,
  setProperty,
  sortableTreeKeyboardCoordinates,
} from "app/components/organisms/buildernew/utilities";
import { useParams } from "react-router-dom";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import Sidebar from "app/components/organisms/buildernew/sidebar";
import { isKeyAvailableInArray } from "app/components/organisms/builder/helpers";
import { useSelector } from "react-redux";
import ModalConfirm from "../modalConfirm";
import { createPortal } from "react-dom";
import { validation } from "app/utils/validators";
import ModalCreateComponentCustom from ".";

export default function ModalComponentCustom(props) {
  const { isOpen = false, isCreate, isDrag, component, buttonText = i18n("label.edit_component"), validateKey, isLoading = false, onSubmit, onDelete, onClose = () => {} } = props;

  let { id } = useParams();

  //CONSTANTS
  const indicator = false;
  const indentationWidth = 30;
  const removable = true;
  const collapsible = true;
  const editable = true;
  const duplicable = true;
  const isSection = true;

  // STATE
  const [value, setValue] = useState(null);
  const [tabs, setTabs] = useState([mainTab]);
  const [tabActive, setTabActive] = useState(0);
  const [formStructure, setFormStructure] = useState(components);
  const [structure, setStructure] = useState([]);
  const [initialItems, setInitialItems] = useState([]);
  const [items, setItems] = useState([]);
  const [overId, setOverId] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [sidebarItem, setSidebarItem] = useState([]);
  const [sidebarRegKey, setSidebarRegKey] = useState(dayjs());
  const [modalConfirm, setModalConfirm] = useState(null);
  const [modalComponent, setModalComponent] = useState(null);
  const [modalCustom, setModalCustom] = useState(null);
  const [validDropTargets, setValidDropTargets] = useState([]);

  //STORE
  const { inputs } = useSelector((store) => store.builder);

  // REF
  const prevItems = useRef(items);
  const refContainer = useRef();

  useEffect(() => {
    if (isOpen) {
      const tempStructure = cleanStructure(components);
      // ON DRAG
      if (isDrag) {
        const tempStructure = cleanStructure(components);
        // KEY
        if (typeof validateKey !== "undefined") {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1) tempStructure[index].rules.push({ method: validateKey, validWhen: true, message: i18n("input.key_already_in_use"), temporary: true });
        }
      }
      // ON CREATE
      else if (component && isCreate) {
        setValue({ title: component.title });
        // REMOVE KEY FIELD ON CREATE
        const index = tempStructure.findIndex((item) => item.key === "key");
        if (index !== -1) tempStructure.splice(index, 1);
      }
      // ON EDIT
      else if (component) {
        // KEY
        if (typeof validateKey !== "undefined") {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1) tempStructure[index].rules.push({ method: validateKey, validWhen: true, message: i18n("input.key_already_in_use"), temporary: true });
        } else {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1) tempStructure.splice(index, 1);
        }
        setValue({ title: component.title, children: component.children });
        setInitialItems(component.children);
        setTabs([mainTab, structureTab]);
      }
      setFormStructure(tempStructure);
    } else {
      setValue(null);
      setInitialItems([]);
      setTabs([mainTab]);
      setTabActive(0);
      setFormStructure(cleanStructure(components));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, component]);

  // ITEMS BUILD TREE
  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree([...items, ...sidebarItem]);
    const collapsedItems = flattenedTree.reduce((acc, { children, collapsed, id, customId }) => ((collapsed && children.length) || customId ? [...acc, id] : acc), []);
    return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId, items, sidebarItem]);

  // ITEMS CONTEXT
  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;
  const projected = activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth) : null;

  // SENSORS
  const sensorContext = useRef({ items: flattenedItems, offset: offsetLeft });
  const [coordinateGetter] = useState(() => sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth));
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    }),
    useSensor(KeyboardSensor, { coordinateGetter })
  );
  useEffect(() => {
    sensorContext.current = { items: [...flattenedItems, ...sidebarItem, ...inputs], offset: offsetLeft };
  }, [flattenedItems, sidebarItem, inputs, offsetLeft]);

  // EFFECT TO REINITIALIZE ITEMS ON INITIAL_ITEMS CHANGE
  useEffect(() => {
    if (JSON.stringify(initialItems) !== JSON.stringify(items)) {
      setItems(initialItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItems]);

  function onUpdateCustomStructure(structure) {
    setItems(structure);
    if (prevItems.current !== structure) {
      setStructure([...structure]);
      prevItems.current = structure;
    }
  }

  const onClickSubmitEdit = () => {
    setFormStructure([...structure]);
    onSubmit({ ...value, children: structure });
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setFormStructure([...structure]);
      if (validation.isValid) onSubmit({ ...value });
    });
  };

  const handleClose = () => {
    if (JSON.stringify(prevItems.current) === JSON.stringify(items)) {
      setModalConfirm({
        isOpen: true,
        type: "CONFIRM",
        title: i18n("label.unsaved_changes"),
        text: i18n("alert.unsaved_changes"),
        onConfirm: () => {
          setModalConfirm(null);
          onClose();
        },
        onClose: () => {
          setModalConfirm(null);
        },
      });
    } else {
      onClose();
    }
  };

  const onClickEnter = (code) => {
    if (code === 13) onClickSubmit();
  };

  const getTitle = () => {
    if (isDrag) {
      return i18n("label.edit_custom_component");
    } else if (isCreate) {
      return i18n("label.add_new_custom_component");
    } else {
      return i18n("label.edit_custom_component");
    }
  };

  return (
    <>
      <Struture isOpen={isOpen} onClose={handleClose} size="sm">
        <Header title={getTitle()} onClose={handleClose} />
        {tabs?.length > 1 && <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />}
        <Content tabs tab={tabActive} noPad>
          <div className="px-12 py-10">
            <Form value={value} onChange={setValue} structure={formStructure} disabled={isLoading} onKeyDown={onClickEnter} />
          </div>
          <div className="relative pl-6  py-6 h-[400px] flex">
            {/* DROPZONE */}
            <div className="flex-1 flex">
              <DndContext
                autoScroll
                sensors={sensors}
                measuring={measuring}
                onDragEnd={handleDragEnd}
                onDragMove={handleDragMove}
                onDragOver={handleDragOver}
                onDragStart={handleDragStart}
                onDragCancel={handleDragCancel}
                collisionDetection={rectIntersection}
              >
                {/* TREE */}
                <div className="p-6 flex-1 overflow-auto max-h-full" ref={refContainer}>
                  <div className="flex flex-col flex-1">
                    <SortableContext id={id} items={sortedIds} strategy={rectSortingStrategy}>
                      <Droppable
                        key={id}
                        id="main"
                        disabled={!items?.length || !validDropTargets.includes(id)}
                        className={`flex flex-col gap-1 p-1 ${!validDropTargets.includes(id) ? "opacity-50 pointer-events-none" : ""}`}
                      >
                        {flattenedItems.map(({ id, children, collapsed, depth, customId, ...data }) => (
                          <SortableTreeItem
                            {...data}
                            key={id}
                            id={id}
                            slug={data?.key}
                            customId={customId}
                            isSection={true}
                            isEditCustom={true}
                            indicator={indicator}
                            indentationWidth={indentationWidth}
                            collapsed={Boolean(collapsed && children.length)}
                            isValidDropTarget={validDropTargets.includes(id)}
                            onEdit={editable ? () => handleEdit(id) : undefined}
                            onCustom={isSection && children?.length ? true : undefined}
                            onRemoveCustom={isSection ? () => handleRemoveCustom(id) : undefined}
                            onRemove={removable ? () => handleRemove(id) : undefined}
                            onDuplicate={duplicable ? () => handleDuplicate(id) : undefined}
                            depth={id === activeId && projected ? projected.depth : depth}
                            onCollapse={collapsible && children?.length ? () => handleCollapse(id) : undefined}
                          />
                        ))}
                      </Droppable>
                      {createPortal(
                        <DragOverlay dropAnimation={dropAnimationConfig} modifiers={indicator ? [adjustTranslate] : undefined}>
                          {activeId && activeItem ? (
                            <SortableTreeItem
                              clone
                              {...activeItem}
                              id={activeId}
                              customId={activeId.customId}
                              isSection={isSection}
                              slug={activeItem.key}
                              depth={activeItem.depth}
                              indentationWidth={indentationWidth}
                              childCount={getChildCount(items, activeId) + 1}
                            />
                          ) : null}
                        </DragOverlay>,
                        document.body
                      )}
                    </SortableContext>
                  </div>
                </div>
                {/* SIDEBAR */}
                <div className={`relative peer text-sm p-4 flex flex-col border-l ease-in-out overflow-auto`}>
                  <div className="flex-1 relative">
                    <Sidebar regKey={sidebarRegKey} onClick={handleAddToEnd} isSection={isSection} />
                  </div>
                </div>
              </DndContext>
            </div>
          </div>
        </Content>
        <Footer
          loading={isLoading}
          cancel={{ text: i18n("button.close"), onClick: handleClose }}
          clean={{ text: i18n("button.delete"), onClick: onDelete }}
          submit={isCreate && component ? { text: buttonText, disabled: isLoading, onClick: onClickSubmit } : { text: buttonText, disabled: isLoading, onClick: onClickSubmitEdit }}
        />
      </Struture>
      <ModalComponent {...modalComponent} />
      <ModalCreateComponentCustom {...modalCustom} />
      <ModalConfirm {...modalConfirm} />
    </>
  );

  function handleDragStart({ active }) {
    const activeId = active?.id;
    setActiveId(activeId);
    setOverId(activeId);
    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove(props) {
    // const clonedItems = [...flattenedItems];
    // const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
    const clonedItems = JSON.parse(JSON.stringify(flattenedItems));

    const overId = props?.over?.id;
    const activeId = props?.active?.id;

    // Encontrar índices do item ativo e do item sobre o qual está
    const activeIndex = clonedItems.findIndex((item) => item.id === activeId);
    const itemIndex = clonedItems.findIndex((item) => item.id === overId);

    // FIRST ELEMENT OF LIST (NO PARENT)
    if (itemIndex <= 0) {
      setOffsetLeft(0);
      return;
    }

    // Determinar o item a considerar
    let prevItem;

    if (itemIndex < activeIndex) {
      // Quando o item está acima da posição inicial
      prevItem = clonedItems[itemIndex - 1];
    } else if (itemIndex === activeIndex) {
      prevItem = clonedItems[itemIndex - 1];
    } else {
      // Quando o item está abaixo da posição inicial
      prevItem = clonedItems[itemIndex];
    }

    // ALLOWED
    if (prevItem && dropAllowedInputs.includes(prevItem.type) && !prevItem.customId) {
      // FORCE INDENTATION
      setOffsetLeft(indentationWidth * (prevItem.depth + 1));
      return;
    }
    // DISALLOWED
    else {
      if (prevItem && typeof prevItem.depth === "number") {
        const maxIndentation = indentationWidth * prevItem.depth;
        setOffsetLeft(Math.min(props.delta.x, maxIndentation));
      } else {
        setOffsetLeft(0);
      }
      return;
    }

    // setOffsetLeft(props.delta.x);
    // return;
  }

  function handleDragOver({ active, over }) {
    const overId = over?.id ?? null;
    const activeId = active?.id ?? null;
    const sidebar = active?.data?.current?.isSidebar;
    const isSection = active?.data?.current?.isSection;
    const title = active?.data?.current?.title;
    const customId = active?.data?.current?.customId;
    const children = active?.data?.current?.children;

    setOverId(overId);

    if (isSection) {
      setSidebarItem([{ id: activeId, index: 0, depth: 0, title: title, children: children, customId: customId }]);
    }

    if (!sidebar) return;

    const input = elements.find((e) => e.type === active?.data?.current?.key);

    if (!input) return;

    setSidebarItem([{ ...input, index: 0, depth: 0, id: activeId, children: children, customId: customId }]);
  }

  function getComponentsAtSameLevel(input) {
    return flattenedItems.filter((item) => item.depth === input.depth && item.id !== input.id);
  }

  function getComponentsAtMainLevel() {
    return flattenedItems.filter((item) => item.depth === 0);
  }

  function placeInput(item, onSuccess, onCancel) {
    if (!item?.type) return onSuccess(item);

    // CUSTOM INPUTS
    if (isSection && item.customId) {
      setModalCustom({
        isOpen: true,
        isCreate: true,
        buttonText: i18n("button.create"),
        validateKey: (key) => isKeyAvailableInArray(null, getComponentsAtSameLevel(item), key),
        onSubmit: (data) => {
          if (onSuccess) onSuccess({ type: item.type, title: data.title, key: data.key });
          setModalCustom(null);
        },
        onClose: () => {
          if (onCancel) onCancel();
          setModalCustom(null);
        },
      });
      return;
    }

    setModalComponent({
      isOpen: true,
      inputType: item?.type,
      buttonText: i18n("label.add_component"),
      inputs: getComponentsAtSameLevel(item),
      validateKey: (key) => isKeyAvailableInArray(null, getComponentsAtSameLevel(item), key),
      onSubmit: (data) => {
        if (onSuccess) onSuccess({ type: item.type, ...data });
        setModalComponent(null);
      },
      onClose: () => {
        if (onCancel) onCancel();
        setModalComponent(null);
      },
    });
  }

  function handleDragEnd({ active, over }) {
    resetState();
    const overId = over?.id;
    const isSidebarItem = sidebarItem?.length;
    const customId = sidebarItem[0]?.customId || null;
    const children = sidebarItem[0]?.children || null;

    // FIRST ELEMENT
    if (overId === "firstDrop") {
      placeInput(
        { ...sidebarItem[0] },
        (data) => {
          if (isSection && customId) {
            onUpdateCustomStructure(buildTree([{ ...data, id: getRandomString(6, false), children, customId }], true));
          } else {
            onUpdateCustomStructure(buildTree([{ ...data, id: getRandomString(6, false) }]));
          }
        },
        () => {
          return;
        }
      );

      return;
    }

    if (!over) return;

    // OTHERS
    const { depth, parentId } = projected || { depth: 0, parentId: null };
    // SEQUENCE
    const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));

    // CHECK IF PARENT IF LIST OR GROUP (ALLOW CHILDREN);
    const parentItem = clonedItems.find((item) => item.id === parentId);
    if (parentItem && !dropAllowedInputs.includes(parentItem.type)) {
      console.error("Não é permitido inserir neste item.");
      return;
    }

    // IF IS A NEW ITEM FROM SIDEBAR
    if (isSidebarItem) {
      let index = over?.data?.current?.sortable?.index;
      if (typeof index === "undefined") index = clonedItems.length;

      const sortedItems = clonedItems;

      // CUSTOM INPUTS
      if (isSection && customId) {
        placeInput({ ...sidebarItem[0] }, (data) => {
          sortedItems.splice(index, 0, { ...data, id: getRandomString(6, false), children, customId, depth, parentId });
          onUpdateCustomStructure(buildTree(sortedItems, true));
        });
        return;
      }
      // DEFAULT
      placeInput({ ...sidebarItem[0] }, (data) => {
        sortedItems.splice(index, 0, { ...data, id: getRandomString(6, false), depth, parentId });
        onUpdateCustomStructure(buildTree(sortedItems));
      });

      return;
    }

    const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
    const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);

    // VERIFY KEYS
    const activeItem = clonedItems[activeIndex];
    const overItem = clonedItems[overIndex];

    if (!parentId) {
      const keyAvailable = isKeyAvailableInArray(null, getComponentsAtMainLevel(), activeItem.key);
    } else if (activeItem?.parentId !== parentId) {
      const keyAvailable = isKeyAvailableInArray(null, getComponentsAtSameLevel(depth > 0 ? overItem.children : overItem), activeItem.key);
      if (!keyAvailable) {
        console.error("Key already in use in this level");
        return;
      }
    }

    const activeTreeItem = clonedItems[activeIndex];
    clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

    const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
    const newItems = buildTree(sortedItems);
    onUpdateCustomStructure(newItems);
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setSidebarItem([]);
    setValidDropTargets([]);
    setSidebarRegKey(dayjs());

    document.body.style.setProperty("cursor", "");
  }

  function handleRemove(id) {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.delete_field"),
      text: i18n("alert.action_delete"),
      onConfirm: () => {
        const newList = removeItem(items, id);
        onUpdateCustomStructure(newList);
        setModalConfirm(null);
      },
      onClose: () => {
        setModalConfirm(null);
      },
    });
  }

  function handleEdit(id) {
    // SEQUENCE
    const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
    const item = clonedItems.find((item) => item.id === id);
    const index = clonedItems.findIndex((item) => item.id === id);

    if (item && index !== -1) {
      setModalComponent({
        isOpen: true,
        component: item,
        buttonText: i18n("label.update_component"),
        inputType: item?.type,
        inputs: getComponentsAtSameLevel(item),
        validateKey: (key) => isKeyAvailableInArray(null, getComponentsAtSameLevel(item), key, item.id),
        onSubmit: (data) => {
          clonedItems[index] = { ...item, ...data };
          const newItems = buildTree(clonedItems);
          onUpdateCustomStructure(newItems);
          setModalComponent(null);
        },
        onClose: () => {
          setModalComponent(null);
        },
      });
    }
  }

  function handleDuplicate(id) {
    const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
    const item = clonedItems.find((item) => item.id === id);
    const index = clonedItems.findIndex((item) => item.id === id);

    const baseKey = item.key.includes("-copy") ? item.key : item.key;

    const existingClones = clonedItems.filter((clonedItem) => clonedItem.key.startsWith(`${baseKey}-copy`));

    const cloneSuffixes = existingClones
      .map((clonedItem) => {
        const match = clonedItem.key.match(/-copy(\d+)$/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((suffix) => suffix !== null);

    let nextSuffix = 1;
    while (cloneSuffixes.includes(nextSuffix)) {
      nextSuffix++;
    }
    const newKey = `${baseKey}-copy${nextSuffix}`;

    const sortedItems = clonedItems;
    sortedItems.splice(index + 1, 0, {
      ...item,
      key: newKey,
      id: getRandomString(6, false),
    });

    onUpdateCustomStructure(buildTree(sortedItems));
  }

  function handleCollapse(id) {
    setItems((items) =>
      setProperty(items, id, "collapsed", (value) => {
        return !value;
      })
    );
  }

  function handleAddToEnd(input) {
    placeInput(
      input,
      (data) => {
        const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
        const sortedItems = clonedItems;
        const customId = input.customId ? input.customId : null;
        const children = input.children ? input.children : null;

        sortedItems.push({ type: input.type, ...data, id: getRandomString(6, false), depth: 0, parentId: null, customId, children });
        onUpdateCustomStructure(buildTree(sortedItems, true));
      },
      () => {
        return;
      }
    );
  }

  // REMOVE CUSTOM INPUT
  function handleRemoveCustom(id) {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.disconnect_custom"),
      text: i18n("alert.action_remove_custom"),
      onClose: () => setModalConfirm(null),
      onConfirm: () => {
        const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
        const updatedStructure = clonedItems.map((el) => {
          if (el.id === id) {
            const { customId, ...rest } = el;
            return rest;
          }
          return el;
        });
        onUpdateCustomStructure(buildTree(updatedStructure));
        setModalConfirm(null);
      },
    });
  }
}
