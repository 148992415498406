import Loading from "app/components/atoms/loading";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPostType from "app/components/molecules/modals/modalPostType";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import SortableTree from "app/components/organisms/buildernew";
import History from "app/components/organisms/buildernew/components/history";
import { i18n } from "app/i18n";
import { builderApi, setActiveHistory } from "app/stores/builder";
import { useDeletePostTypeMutation, useGetPostTypeQuery, useUpdatePostTypeMutation } from "app/stores/builder";
import { getSource } from "app/utils/content";
import { showServerError } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export default function PostTypesEdit(props) {
  const { reloadList, onDuplicate } = props;

  // ROUTER
  const navigate = useNavigate();
  let { project, id } = useParams();

  // STATE
  const [timer, setTimer] = useState(null);
  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [previewModal, setPreviewModal] = useState(null);

  // STORE
  const dispatch = useDispatch();
  const { active, history } = useSelector((store) => store.builder);

  // QUERY
  let { data, isLoading: isGetting, isFetching, isUninitialized, isError: isErrorGet, refetch: refetchPostTypes } = useGetPostTypeQuery({ id, project }, { skip: !id || !project });

  // MUTATION
  const [updatePostType, { isLoading: isUpdating, isError: isErrorUpdate }] = useUpdatePostTypeMutation();
  const [deletePostType, { isLoading: isDeleting, isError: isErrorDelete }] = useDeletePostTypeMutation();

  // VARS
  const isLoading = isFetching || isGetting || isUpdating || isDeleting;
  const isError = isErrorGet || isErrorUpdate || isErrorDelete;
  const isPages = data?.key === "pages";

  useEffect(() => {
    return () => {
      setStructure(null);
      dispatch(builderApi.util.invalidateTags([{ type: "PostType" }]));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // EDIT POSTTYPES SETTINGS
  const onEdit = () => {
    setModal({
      isOpen: true,
      postType: data,
      project: project,
      buttonText: i18n("button.update_postType"),
      onSubmit: (values) => {
        updatePostType({ id: data.id, ...values }).then((res) => {
          if (res?.data) {
            reloadList();
            refetchPostTypes();
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  // DROP ALL INPUTS IN STRUCTURE
  const onEmptize = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.clean_all_inputs"),
      forceNotice: data.inUse,
      notice: i18n("alert.action_clean_page"),
      onConfirm: () => {
        updatePostType({ id: data.id, structure: [] }).then((res) => {
          if (res?.data) {
            setStructure([]);
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // ON DUPLICATE
  const onClickDuplicate = () => {
    onDuplicate(data?.structure);
  };

  // DELETE POSTTYPES
  const onDelete = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      buttonText: i18n("button.delete"),
      title: i18n("label.delete_postTypes"),
      text: i18n("alert.postTypes_delete"),
      forceNotice: data.inUse,
      notice: i18n("alert.postTypes_in_use"),
      onConfirm: () => {
        deletePostType({ id: data.id }).then((res) => {
          if (res && res.data) {
            reloadList();
            navigate(`/project/${project}/builder/posttypes`);
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // OPEN THUMBNAIL
  const onOpenPreview = () => {
    setPreviewModal({
      isOpen: true,
      href: getSource(data?.thumbnail?.href),
      onClose: () => setPreviewModal(null),
    });
  };

  // ON SAVE STRUCTURE
  function onSave(structure, history) {
    updatePostType({ id: data.id, structure }).then((res) => {
      if (res?.data) {
        if (!history) setStructure(res?.data?.structure);
      } else {
        showServerError(res);
      }
    });
  }

  // UPDATE FROM HISTORY
  const onUseHistory = (isNext) => {
    if (!history || history?.length < 2) return null;

    const direction = isNext ? -1 : 1;
    const newActive = active + direction;

    if (newActive >= history.length || newActive < 0 || !history.at(newActive)) return null;

    setStructure(history.at(newActive));
    dispatch(setActiveHistory(newActive));

    // STOP TIMER IF EXISTS
    if (timer) clearTimeout(timer);

    // UPDATE IN DATABASE
    const newTimer = setTimeout(() => {
      onSave(history.at(newActive), true);
    }, 1400);

    // SET NEW TIMER
    setTimer(newTimer);
  };

  useEffect(() => {
    if (data?.id) setStructure(data?.structure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!id) return <Navigate to={`/project/${project}/builder/posttypes`} />;
  if (isUninitialized) return <Loading active className="h-full flex items-center justify-center" />;

  return (
    <>
      <SortableTree title={data?.title} slug={data?.key} isLoading={isLoading} isError={isError} initialItems={structure ? [...structure] : []} onSave={onSave}>
        <History active={active} length={history?.length || 0} onNext={() => onUseHistory(true)} onPrev={() => onUseHistory(false)} />
        {data?.thumbnail?.href && (
          <button className="btn-primary-outline mx-2" onClick={onOpenPreview}>
            {i18n("label.preview")}
          </button>
        )}
        <Dropdown
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
            { title: i18n("button.duplicate"), onClick: onClickDuplicate, icon: "copy", disabled: isPages },
            { title: i18n("button.cleanStructure"), onClick: onEmptize, icon: "clean" },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true, disabled: isPages },
          ]}
        />
      </SortableTree>

      <ModalPostType {...modal} />
      <ModalPreview {...previewModal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
