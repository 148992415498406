import shortid from "shortid";
import { isArray } from "../validators/dataValidator";

export const adjustTextareaHeight = (textarea) => {
  textarea.style.height = "auto";
  textarea.style.height = `${textarea.scrollHeight}px`;
};

export const adjustInputWidth = (inputElement) => {
  const contentWidth = inputElement.scrollWidth;
  inputElement.style.width = contentWidth + "px";
};

//////////////////////////////////
////////// PROJECT MENU //////////
//////////////////////////////////

// UPDATE TITLES IN MENU
export const updateMenuPostTitle = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // UPDATE PAGES
  if (newData.type === "post" && temp?.postTypes) {
    const index = temp.postTypes.findIndex((e) => e.posts.find((o) => o.id === newData.post.id));
    if (index !== -1) {
      const indexPost = temp.postTypes[index].posts.findIndex((e) => e.id === newData.post.id && e.content.language.slug === newData.language.slug);
      if (indexPost !== -1) temp.postTypes[index].posts[indexPost].content = { title: newData.title, slug: newData.slug, language: newData.language };
    }
  }

  // UPDATE BLOCKS
  if (newData.type === "block" && temp?.blocks) {
    const index = temp.blocks.findIndex((e) => e.id === newData.block.id);
    if (index !== -1) temp.blocks[index].content = { title: newData.title, language: newData.language };
  }

  dispatch(slice.actions.setInfo(temp));
};

// ADD TO MENU
export const addNewPostToMenu = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  if (!newData) return null;

  // ADD PAGE
  if (newData?.content?.type === "post" && temp?.postTypes) {
    const index = temp.postTypes.findIndex((e) => e.key === newData.postType.key);
    if (index !== -1) {
      temp.postTypes[index].count++;
      temp.postTypes[index].posts.unshift({ id: newData.id, content: newData.content });
    }
  }

  // ADD BLOCK
  if (newData?.content?.type === "block" && temp?.blocks) {
    temp.blocks.push({ id: newData.id, contentType: newData.contentType, translations: newData.translations });
  } else if (newData?.type === "block" && temp?.blocks) {
    const index = temp.blocks.findIndex((e) => e.id === newData.block.id);
    if (index !== -1) temp.blocks[index].translations.push({ ...newData });
    else temp.blocks.push({ id: newData.block.id, contentType: newData.block.contentType, translations: [{ ...newData }] });
  }

  dispatch(slice.actions.setInfo(temp));
};

// REMOVE FROM MENU
export const removePostFromMenu = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // REMOVE PAGES
  const indexPages = temp.postTypes.findIndex((e) => e.posts.find((o) => o.id === newData.id));
  if (indexPages !== -1) {
    const indexPost = temp.postTypes[indexPages].posts.findIndex((e) => e.id === newData.id);
    if (indexPost !== -1) temp.postTypes[indexPages].posts.splice(indexPost, 1);
  }

  // REMOVE BLOCKS
  const indexBlock = temp.blocks.findIndex((e) => e.id === newData.id);
  if (indexBlock !== -1) temp.blocks.splice(indexBlock, 1);

  dispatch(slice.actions.setInfo(temp));
};

//////////////////////////////////
//////// FILE CATEGORIES  ////////
//////////////////////////////////

// UPDATE LIST OF FILE CATEGORIES
export const updateListOfFileCategories = (current, newData, language, slice, dispatch) => {
  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // UPDATE LIST
  for (let i = 0; i < newData.length; i++) {
    const index = temp.findIndex((item) => newData[i].id === item.value);
    if (index === -1) {
      temp.push({
        value: newData[i].id,
        label: newData[i].translations.find((t) => t.language.slug === language.slug)?.value,
      });
    }
  }

  dispatch(slice.actions.setFileCategories(temp));
};

//////////////////////////////////
///////////// FILES  /////////////
//////////////////////////////////

// ADD NEW
export const addFilesToMediaList = (current, newData, addToEnd, slice, dispatch) => {
  if (!current?.length || !newData) return null;

  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // CHECK IF IS ONE IMAGE OR MULTIPLES
  const isMulti = isArray(newData, true);

  if (isMulti) {
    for (let i = 0; i < newData.length; i++) {
      const index = temp.findIndex((e) => e.id === newData[i].id);
      if (index !== -1) temp[index] = { ...temp[index], ...newData };
      else if (addToEnd) temp.push(newData[i]);
      else temp.unshift(newData[i]);
    }
  } else {
    const index = temp.findIndex((e) => e.id === newData.id);
    if (index !== -1) temp[index] = { ...temp[index], ...newData };
    else if (addToEnd) temp.push(newData);
    else temp.unshift(newData);
  }

  dispatch(slice.actions.setFiles(temp));
};

// UPDATE ITEM
export const editFileInMediaList = (current, newData, slice, dispatch) => {
  addFilesToMediaList(current, newData, false, slice, dispatch);
};

// DELETE ONE
export const removeFilesFromMediaList = (current, newData, slice, dispatch) => {
  if (!current?.length || !newData) return null;

  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // CHECK IF IS ONE IMAGE OR MULTIPLES
  const isMulti = isArray(newData, true);

  if (isMulti) {
    for (let i = 0; i < newData.length; i++) {
      const index = temp.findIndex((e) => e.id === newData[i].id);
      if (index !== -1) temp.splice(index, 1);
    }
  } else {
    const index = temp.findIndex((e) => e.id === newData.id);
    if (index !== -1) temp.splice(index, 1);
  }

  dispatch(slice.actions.setFiles(temp));
};

//////////////////////////////////
///////////// INPUTS /////////////
//////////////////////////////////

// ADD POSTS TO INPUTS STORE
export const addPostsToStore = (current, newData, slice, dispatch) => {
  if (!current?.length || !newData) return null;

  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // ADD NEW POSTS
  if (newData?.length) {
    for (let i = 0; i < newData.length; i++) {
      const index = temp.findIndex((e) => e.id === newData[i].id && e.language === newData[i].language);
      if (index === -1) temp.push(newData[i]);
    }
  }
  dispatch(slice.actions.setPosts(temp));
};

//////////////////////////////////
//////////// SECTION  ////////////
//////////////////////////////////

// UPDATE THE STRUCTURE OF SECTION ACTIVE (BUILDER TOOL)
export const updatePostWithNewStructure = (current, newData, section, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // UPDATE SECTION
  if (temp?.template?.sections?.length) {
    const index = temp.template.sections.findIndex((e) => e.section.id === section);
    if (index !== -1) temp.template.sections[index].section.structure = [...newData];
  }
  dispatch(slice.actions.setPost(temp));
};

//////////////////////////////////
//////////// PROJECT  ////////////
//////////////////////////////////

// UPDATE PROJECT SETTINGS
export const updateProjectSettings = (current, newData, slice, dispatch) => {
  if (!current || !newData) return null;
  if (newData.id !== current.id) return null;

  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  temp = { ...temp, ...newData };

  dispatch(slice.actions.setProject(temp));
  dispatch(slice.actions.setLanguages(temp.languages));
};

// UPDATE CURRENT PROJECT IN GLOBAL LIST
export const updateProjectsList = (current, newData, slice, dispatch) => {
  if (!current?.length || !newData) return null;

  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const index = temp.findIndex((e) => e.id === newData.id);
  if (index === -1) return null;

  temp[index] = {
    name: newData.name,
    slug: newData.slug,
    enabled: newData.enabled,
    languages: newData.languages,
    thumbnail: newData.thumbnail,
  };

  dispatch(slice.actions.setProjects(temp));
};

//////////////////////////////////
///////////// BLOCK  /////////////
//////////////////////////////////

// UPDATE ACTIVE BLOCK DATA
export const updateActiveBlock = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const index = temp.translations.findIndex((e) => e.id === newData.id);
  if (index !== -1) temp.translations[index] = { ...temp.translations[index], ...newData };

  dispatch(slice.actions.setBlock({ ...temp }));
};

// ADD NEW REVISION TO BLOCK
export const addRevisionToBlock = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const index = temp.translations.findIndex((e) => e.id === newData.id);
  if (index !== -1) temp.translations[index].revisions = newData.revisions;

  dispatch(slice.actions.setBlock({ ...temp }));
};

//////////////////////////////////
////////////// POST //////////////
//////////////////////////////////

// UPDATE POST
export const updateActivePost = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const index = temp.translations.findIndex((e) => e.id === newData.id);
  if (index !== -1) temp.translations[index] = { ...temp.translations[index], ...newData };

  if (newData?.post?.postType?.categories) {
    temp.postType.categories = [...newData.post.postType.categories];
  }

  dispatch(slice.actions.setPost({ ...temp }));
};

// UPDATE SECTION STATION
export const updateSectionStatusInPost = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const indexContent = temp.translations.findIndex((e) => e.id === newData.content.id);
  if (indexContent !== -1) {
    const indexSection = temp.translations[indexContent].sections.findIndex((e) => e.id === newData.id);
    if (indexSection !== -1) temp.translations[indexContent].sections[indexSection].enabled = newData.enabled;
  }

  dispatch(slice.actions.setPost({ ...temp }));
};

// ADD NEW REVISION TO POST
export const addRevisionToPost = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const index = temp.translations.findIndex((e) => e.id === newData.id);
  if (index !== -1) temp.translations[index].sections = newData.sections;

  dispatch(slice.actions.setPost({ ...temp }));
};

//////////////////////////////////
////////////// USERS /////////////
//////////////////////////////////

// UPDATE LIST OF USERS
export const refreshListUsers = (current, dispatch, slice, updated, created, deleted) => {
  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  if (created) {
    temp.push(created);
  }
  if (updated) {
    const index = temp.findIndex((e) => e.id === updated.id);
    if (index !== -1) temp[index] = { ...updated };
  }
  if (deleted) {
    const index = temp.findIndex((e) => e.id === deleted.id);
    if (index !== -1) temp.splice(index, 1);
  }

  dispatch(slice.actions.setUsers([...temp]));
};

//////////////////////////////////
///////////// BUILDER ////////////
//////////////////////////////////

// BUILD HISTORY ARRAY
export const addItemToBuilderHistory = (current, active, noChangeActive, newData, slice, dispatch) => {
  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  // IF HISTORY ACTIVED, DELETE ELEMENTS AFTER TO ADD NEW ONE
  if (active) temp.splice(0, active);

  // ADD NEW ONE
  temp.unshift(newData);

  dispatch(slice.actions.setHistory([...temp]));
  if (!noChangeActive) dispatch(slice.actions.setActiveHistory(0));
};

// CLEAN HISTORY
export const clearBuilderHistory = (dispatch, slice) => {
  dispatch(slice.actions.setHistory([]));
  dispatch(slice.actions.setActiveHistory(0));
};

// BUILD CUSTOM INPUTS ARRAY
export const prepareCustomInputs = (current, input, erase) => {
  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(isArray(current, true) ? current : [])));

  const index = temp.findIndex((e) => e.id === input.id);
  if (index !== -1 && erase) {
    temp.splice(index, 1);
  } else if (index !== -1 && !erase) {
    delete input.id;
    temp[index] = {
      ...temp[index],
      ...input,
      children: input.children || temp[index].children,
    };
  } else if (!erase) {
    temp.push({ ...input });
  }

  return temp;
};

//////////////////////////////////
////////// TRANSLATIONS //////////
//////////////////////////////////

export const addTranslationToStore = (current, total, newList, skip, newElement, slice, dispatch) => {
  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  if (skip) temp = [...temp, ...newList];
  else if (newList) temp = [...newList];
  else if (newElement) temp = [{ ...newElement, highlight: true }, ...temp];

  if (newElement) {
    setTimeout(() => {
      temp = temp.map((e) => {
        let option = { ...e };
        if (option?.highlight) delete option.highlight;
        return { ...option };
      });
      dispatch(slice.actions.setTranslations([...temp]));
    }, 4000);
    dispatch(slice.actions.setTotal(total + 1));
  }

  dispatch(slice.actions.setTranslations([...temp]));
};

export const updateOrDeleteTranslationInStore = (current, total, newData, toDelete, slice, dispatch) => {
  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const index = temp.findIndex((e) => e.id === newData.id);
  if (toDelete && index !== -1) temp.splice(index, 1);
  else if (!toDelete && index !== -1) temp[index] = { ...newData };

  dispatch(slice.actions.setTranslations([...temp]));
  if (toDelete && index !== -1) dispatch(slice.actions.setTotal(total - 1));
};

//////////////////////////////////
/////////// CATEGORIES ///////////
//////////////////////////////////

export const addCategoriesToInfo = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const postTypeIndex = temp.postTypes.findIndex((e) => e.id === newData.categoryType.postType.id);
  if (postTypeIndex !== -1) {
    const categoryTypeIndex = temp.postTypes[postTypeIndex].categories.findIndex((e) => e.id === newData.categoryType.id);
    if (categoryTypeIndex !== -1) {
      temp.postTypes[postTypeIndex].categories[categoryTypeIndex].options.push({
        id: newData.id,
        key: newData.key,
        icon: newData.icon,
        thumbnail: newData.thumbnail,
        translations: newData.translations,
      });
    }
  }

  dispatch(slice.actions.setInfo({ ...temp }));
};

export const updateCategoriesInInfo = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  const postTypeIndex = temp.postTypes.findIndex((e) => e.id === newData.categoryType.postType.id);
  if (postTypeIndex !== -1) {
    const categoryTypeIndex = temp.postTypes[postTypeIndex].categories.findIndex((e) => e.id === newData.categoryType.id);
    if (categoryTypeIndex !== -1) {
      const categoryIndex = temp.postTypes[postTypeIndex].categories[categoryTypeIndex].options.findIndex((e) => e.id === newData.id);
      if (categoryIndex !== -1) {
        temp.postTypes[postTypeIndex].categories[categoryTypeIndex].options[categoryIndex] = {
          id: newData.id,
          key: newData.key,
          icon: newData.icon,
          thumbnail: newData.thumbnail,
          translations: newData.translations,
        };
      }
    }
  }

  dispatch(slice.actions.setInfo({ ...temp }));
};

export const deleteCategoriesInInfo = (current, newData, slice, dispatch) => {
  let temp = {};
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  let postTypeIndex = null;
  let categoryIndex = null;
  let optionIndex = null;

  postTypeIndex = temp.postTypes.findIndex((posttype) => {
    categoryIndex = posttype.categories.findIndex((category) => {
      optionIndex = category.options.findIndex((option) => {
        return option.id === newData.id;
      });
      return optionIndex !== -1;
    });
    return categoryIndex !== -1;
  });

  if (postTypeIndex !== -1 && categoryIndex !== -1 && optionIndex !== -1) {
    temp.postTypes[postTypeIndex].categories[categoryIndex].options.splice(optionIndex, 1);
  }

  dispatch(slice.actions.setInfo({ ...temp }));
};

//////////////////////////////////
////////// ENVIRONMENTS //////////
//////////////////////////////////

export const updateListOfEnvironments = (current, newDataGroup, newData, slice, dispatch) => {
  let temp = [];
  Object.assign(temp, JSON.parse(JSON.stringify(current)));

  if (newDataGroup?.length) {
    temp = [...newDataGroup];
  } else if (newData) {
    const index = temp.findIndex((e) => e.key === newData.key);
    if (index !== -1) temp[index].value = newData.value;
    else temp.push(newData);
  }

  dispatch(slice.actions.setEnvironments([...temp]));
};
