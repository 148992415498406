import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { components } from "./constants";
import { i18n } from "app/i18n";
import { useSelector } from "react-redux";
import { getFlag } from "app/utils/languages";

export default function ModalBlock(props) {
  const { isOpen = false, contentTypes, translations, languageSlug, isLoading, buttonText = i18n("button.create"), onSubmit, onClose = () => {} } = props;

  // STORE
  const { project, language } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (isOpen) {
      const tempStructure = cleanStructure([...components]);
      // TEMPLATES
      if (contentTypes && contentTypes.length) {
        const index = tempStructure.findIndex((e) => e.key === "contentType");
        if (index !== -1) tempStructure[index].options = contentTypes.map((e) => ({ value: e.id, label: e.title }));
      }
      // TRANSLATIONS
      if (translations?.length) {
        const index = tempStructure.findIndex((e) => e.key === "contentType");
        if (index !== -1) tempStructure.splice(index, 1);
        const indexT = tempStructure.findIndex((e) => e.key === "origin");
        if (indexT !== -1) tempStructure[indexT].options = translations.map((e) => ({ value: e.language.id, label: `${getFlag(e.language.slug)} ${e.language.name}` }));
      } else {
        const index = tempStructure.findIndex((e) => e.key === "copy");
        if (index !== -1) tempStructure.splice(index, 1);
        const indexL = tempStructure.findIndex((e) => e.key === "origin");
        if (indexL !== -1) tempStructure.splice(indexL, 1);
        const indexT = tempStructure.findIndex((e) => e.key === "translate");
        if (indexT !== -1) tempStructure.splice(indexT, 1);
      }
      // PROJECT & LANGUAGE
      if (project && (language || languageSlug)) {
        const index = tempStructure.findIndex((e) => e.key === "key");
        if (index !== -1) {
          const ruleIndex = tempStructure[index].rules.findIndex((e) => e.args);
          if (ruleIndex !== -1) {
            tempStructure[index].rules[ruleIndex].args.project = project.id;
            tempStructure[index].rules[ruleIndex].args.language = languageSlug || language.slug;
          }
        }
      }
      setStructure([...tempStructure]);
    } else {
      setValue(null);
      setStructure(cleanStructure(components));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, project, language, translations, contentTypes]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSubmit({ ...value, project, language });
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("button.create_block")} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
