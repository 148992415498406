import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// GRAPH
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

// COMPONENTS
import Select from "../../select";

// HELPERS
import { isArray, isObject } from "app/utils/validators/dataValidator";

export default function SelectPost(props) {
  const { title, value, description, multiple = false, posttypes, error, errorMessage, mode, isRequired, onChange } = props;

  let styles = {
    title: "block text-sm font-medium text-slate-700",
    box: "bg-gray-50 rounded-lg border",
  };

  // PARAMS
  const { lang } = useParams();

  // TEMPORARY TYPE
  const [tempType, setTempType] = useState(null);

  // STORE
  const { types, posts } = useSelector((state) => state.inputs);

  if (mode === "input-button") {
    styles.box += " -mt-[1px]";
  } else if (mode === "sidebar") {
    styles.box = "";
  } else {
    styles.box += " px-5 pt-5 pb-3";
  }

  if (error) {
    styles.box += " border-red-600";
    styles.title += " text-red-600";
  } else {
    styles.box += " border-transparent";
    styles.title += " text-slate-700";
  }

  // STYLE WHEN JUST 1 INPUT
  const optionsTypes = types?.length && posttypes?.length ? types.filter((e) => posttypes.includes(e.value)) : types;
  if (optionsTypes?.length === 1) styles.box = "[&_label]:hidden";

  useEffect(() => {
    if (optionsTypes?.length === 1) {
      if (value?.type?.value !== optionsTypes[0].value) setTempType(optionsTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsTypes]);

  const onValue = () => {
    if (!value) return undefined;

    if (multiple) {
      if (isArray(value, true)) return value?.filter((e) => e.post)?.map((e) => e.post);
      else if (isObject(value, true)) return [value?.post];
    } else {
      if (isArray(value, true)) return value[0]?.post;
      else if (isObject(value, true)) return value?.post;
    }

    return undefined;
  };

  const onChangeValue = (_v) => {
    let formattedPosts = undefined;

    function fillObj(e) {
      const type = optionsTypes.find(({ value }) => value === e.type);
      return {
        type: type,
        post: {
          value: e.value,
          label: e.label,
          ...(e.webId ? { webId: e.webId } : {}),
        },
      };
    }

    if (multiple) {
      if (isArray(_v, true)) formattedPosts = _v.map((e) => fillObj(e));
      else if (isObject(_v, true)) formattedPosts = [fillObj(_v)];
    } else {
      if (isArray(_v, true)) formattedPosts = fillObj(_v[0]);
      else if (isObject(_v, true)) formattedPosts = fillObj(_v);
    }
    onChange(formattedPosts);
  };

  return (
    <div className={`block mb-3 ${mode ? mode : ""}`}>
      <div className="flex items-end justify-between">
        <div>
          {title && <span className={styles.title}>{title}</span>}
          {description && <span className="block text-xs font-light text-slate-400 -mt-1.5 mb-1">{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={styles.box}>
        <div className="flex gap-4">
          {/* TYPE */}
          {optionsTypes?.length > 1 && (
            <Select
              key="type"
              title="Post Type"
              options={optionsTypes}
              placeholder="Select a Post Type"
              value={value?.type || tempType}
              onChange={(e) => onChange({ type: e })}
              placement={mode === "sidebar" ? "top" : undefined}
            />
          )}
          {/* POSTS */}
          <Select
            loadmore
            key="post"
            title="Post"
            value={onValue()}
            multiple={multiple}
            onChange={onChangeValue}
            placeholder="Select a Post"
            posttype={value?.type?.value || tempType?.value}
            placement={mode === "sidebar" ? "top" : undefined}
            options={posts
              ?.filter((e) => e.posttype === value?.type?.value && e.language === lang)
              .map((e) => ({ value: e.id, label: e.title, type: e.posttype, ...(e?.webId ? { webId: e.webId } : undefined) }))}
          />
        </div>
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
