import { inputsApi } from "app/stores/inputs";
import project from "app/stores/project";
import { cleanString } from "app/utils/content";

const FULFILLED = "fulfilled";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

let prevPostType = undefined;

export const loadOptions = async (search, prevOptions = [], posttype, languageSlug, projectId, dispatch) => {
  await sleep(1000);
  if (!projectId || !languageSlug) return { options: [], hasMore: false };

  try {
    let allOptions = [...prevOptions?.filter((e) => !posttype?.id || e.type === posttype?.id)];
    let hasMore = false;
    let skip = allOptions?.length;

    if (posttype?.id && prevPostType !== posttype?.id) {
      prevPostType = posttype?.id;
      allOptions = [];
    } else if (posttype?.total && skip >= posttype?.total) {
      return {
        options: allOptions
          ?.filter((item) => {
            if (!search) return true;
            return item.translations.some((e) => e.language.slug === languageSlug && (cleanString(e.title).includes(cleanString(search)) || cleanString(e.slug).includes(cleanString(search))));
          })
          ?.filter((item) => item?.translations?.length)
          ?.map((item) => {
            const content = item?.translations?.find((e) => e.language.slug === languageSlug);
            return {
              value: item?.id,
              type: item?.typeId,
              label: content?.title,
              ...(item?.webId ? { webId: item.webId } : undefined),
            };
          }),
        hasMore: true,
      };
    }

    const { data, status } = await dispatch(
      inputsApi.endpoints.getInputPosts.initiate(
        {
          take: 15,
          skip: skip,
          where: { postType: { id: posttype?.id }, project: { id: projectId } },
          orderBy: { createdAt: "desc" },
        },
        {
          forceRefetch: true,
        }
      )
    );

    if (status === FULFILLED && data?.length) {
      const newOptions = data;
      allOptions = [...allOptions, ...newOptions];
      hasMore = posttype?.id ? allOptions?.length >= posttype?.total : false;
    } else {
      hasMore = false;
    }

    return {
      options: allOptions
        ?.filter((item) => {
          if (!search) return true;
          return item.translations.some((e) => e.language.slug === languageSlug && cleanString(e.title).includes(cleanString(search)));
        })
        ?.filter((item) => item?.translations?.find((e) => e.language.slug === languageSlug))
        ?.map((item) => {
          const content = item?.translations?.find((e) => e.language.slug === languageSlug);
          return {
            value: item?.id,
            type: item?.typeId,
            label: content?.title,
            ...(item?.webId ? { webId: item.webId } : undefined),
          };
        }),
      hasMore: hasMore,
    };
  } catch (error) {
    console.error(error);
    return { options: prevOptions, hasMore: false };
  }
};
