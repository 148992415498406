import { useEffect } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Avatar from "app/components/atoms/inputs/avatar";
import Text from "app/components/atoms/inputs/text";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { validation } from "app/utils/validators";
import { structure, structurePassword } from "./constants";
import { useUpdateMeMutation } from "app/stores/users";
import { showServerError, showServerSuccess } from "app/utils/server";
import PasswordStrength from "../../passwordStrength";
import { i18n, getLocale, getLocales } from "app/i18n";
import Select from "app/components/atoms/inputs/select";
// import i18next, { changeLanguage } from "i18next";

export default function ModalAccount(props) {
  const { isOpen, toggleLanguage, onClose } = props;

  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [updateMe, { isLoading }] = useUpdateMeMutation();

  useEffect(() => {
    if (isOpen) {
      if (user) setValue({ name: user.name, photo: user.photo, email: user.email, role: user.role });
    } else {
      setValue(null);
      setErrors(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const showError = (key) => {
    if (!errors || !errors.length) return {};
    const i = errors.findIndex((e) => e.key === key);
    if (i !== -1) return { error: errors[i].error, errorMessage: errors[i].errorMessage };
    else return {};
  };

  const checkMainFields = () => {
    validation(structure, { photo: value.photo, name: value.name }, (structure, { isValid }) => {
      setErrors([...structure.filter((e) => e.error)]);
      if (isValid) {
        if (value.password) checkPasswordFields();
        else
          updateMe({ id: user.id, name: value.name, photo: value.photo }).then((res) => {
            if (res && res.data) {
              onClose();
              showServerSuccess(i18n("toast.success_update"));
            } else {
              showServerError(res);
            }
          });
      }
    });
  };

  const checkPasswordFields = () => {
    const structure = [...structurePassword];
    // INSERT ID TO VALIDATE PASSWORD
    const index = structure.findIndex((e) => e.key === "password");
    if (index !== -1) structure[index].rules[1].args.id = user.id;

    validation(structure, { password: value.password, newPassword: value.newPassword, confirm: value.confirm }, (structure, { isValid }) => {
      setErrors([...structure.filter((e) => e.error)]);
      if (isValid)
        updateMe({ id: user.id, name: value.name, photo: value.photo, password: value.password, newPassword: value.newPassword }).then((res) => {
          if (res && res.data) {
            onClose();
            showServerSuccess(i18n("toast.success_update"));
          } else {
            showServerError(res);
          }
        });
    });
  };

  const onClickSubmit = () => {
    if (value) checkMainFields();
  };

  // const onChangeLanguage = (locale) => {
  //   changeLocale(locale);
  // };

  const activeLanguage = getLocale();
  const languages = getLocales();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <div className="fixed inset-0 bg-black/25" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-blue-700 px-4 py-6 sm:px-6 mb-10">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white mt-10">{i18n("label.your_account")}</Dialog.Title>
                            <p className="text-sm text-white">{i18n("label.update_your_account")}</p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button type="button" className="text-white hover:text-gray-200" onClick={onClose}>
                              <span className="sr-only">{i18n("label.close_panel")}</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Image */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div />
                          <div className="sm:col-span-2">
                            <Avatar value={value?.photo} onChange={(photo) => setValue({ ...value, photo })} />
                          </div>
                        </div>

                        {/* Name */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                              {i18n("label.name")}
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Text value={value?.name} onChange={(name) => setValue({ ...value, name })} {...showError("name")} />
                          </div>
                        </div>

                        {/* Email */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                              {i18n("label.email")}
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Text value={value?.email} disabled onChange={(email) => setValue({ ...value, email })} />
                          </div>
                        </div>

                        {/* Role */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                              {i18n("label.role")}
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Text value={value?.role} disabled onChange={(role) => setValue({ ...value, role })} />
                          </div>
                        </div>

                        <div className="pt-20 pb-4 px-4 sm:px-6">
                          <h2 className="text-base font-semibold leading-6 text-gray-900">{i18n("label.change_language")}</h2>
                        </div>

                        {!!languages?.length && (
                          <>
                            {languages.map(({ slug, name }, index) => (
                              <button key={index} className={slug === activeLanguage ? "bg-primary-600 text-white" : "bg-white text-black"} onClick={() => toggleLanguage(slug)}>
                                {name}
                              </button>
                            ))}
                          </>
                        )}

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                              {i18n("label.current_language")}
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Select options={languages} onChange={(e) => toggleLanguage(e.value)} value={languages.find((e) => e.value === activeLanguage)} />
                          </div>
                        </div>

                        <div className="pt-20 pb-4 px-4 sm:px-6">
                          <h2 className="text-base font-semibold leading-6 text-gray-900">{i18n("label.change_password")}</h2>
                        </div>

                        {/* Password */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                              {i18n("label.current_password")}
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Text type="password" autoComplete="new-password" value={value?.password} onChange={(password) => setValue({ ...value, password })} {...showError("password")} />
                          </div>
                        </div>

                        {value?.password && (
                          <>
                            {/* New Password */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                                  {i18n("label.new_password")}
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <Text
                                  type="password"
                                  autoComplete="new-password"
                                  value={value?.newPassword}
                                  onChange={(newPassword) => setValue({ ...value, newPassword })}
                                  {...showError("newPassword")}
                                />
                              </div>
                            </div>
                            {/* New Password Confirm */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
                                  {i18n("label.confirmPassword")}
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <Text type="password" autoComplete="new-password" value={value?.confirm} onChange={(confirm) => setValue({ ...value, confirm })} {...showError("confirm")} />
                              </div>
                            </div>
                            {/* Password Strength Display */}
                            <div className="space-y-2 p-4">
                              <PasswordStrength withInfo disabled={isLoading} value={value?.newPassword} />
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {/** Action buttons **/}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button type="button" onClick={onClose} disabled={isLoading} className="btn-primary-outline">
                          {i18n("button.cancel")}
                        </button>
                        <button type="button" className="btn-primary" disabled={isLoading} onClick={onClickSubmit}>
                          {i18n("button.update")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
