import LabelSyncStatus from "app/components/molecules/labels/labelSyncStatus";
import React from "react";

export default function BuilderHeader(props) {
  const { title, slug, isLoading, isError, children, isEditCustom } = props;

  return (
    <div className="flex justify-between items-center flex-wrap p-6">
      <div className="mt-3">
        <h1 className="text-text font-bold text-lg leading-none">{title}</h1>
        <span className="text-sm text-gray-500">{slug}</span>
      </div>
      <div className="flex items-center mt-3">
        {!isEditCustom && <LabelSyncStatus isLoading={isLoading} isError={isError} />}
        {children && <div className="flex justify-end items-center w-full lg:w-fit">{children}</div>}
      </div>
    </div>
  );
}
