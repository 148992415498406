import { Menu, Transition } from "@headlessui/react";
import IconMinus from "app/components/atoms/icons/iconMinus";
import IconPlus from "app/components/atoms/icons/iconPlus";
import IconX from "app/components/atoms/icons/iconX";
import Dropzone from "app/components/organisms/builder/Dropzone";
import elements from "app/components/organisms/builder/helpers/elements";
import { i18n } from "app/i18n";
import { useUpdateSectionMutation } from "app/stores/builders";
import { postSlice } from "app/stores/post";
import { showServerError, showServerSuccess } from "app/utils/server";
import { arraysAreEqual } from "app/utils/validators/dataValidator";
import { updatePostWithNewStructure } from "app/utils/visual";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import ModalComponent from "../modals/modalComponent";

export default function BuilderTool(props) {
  const { active, setActive, section, refetchSection } = props;

  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [structureChanged, setStructureChanged] = useState(false);

  // STORE
  const dispatch = useDispatch();
  const { post } = useSelector((state) => state.post);

  // REQUEST
  const [updateSection, { isLoading }] = useUpdateSectionMutation();

  useEffect(() => {
    if (section && section.structure) setStructure(section.structure);
  }, [section]);

  useEffect(() => {
    if (section && structure) setStructureChanged(!arraysAreEqual(section.structure, structure));
  }, [section, structure]);

  const onUpdate = (newStructure) => {
    setStructure([...newStructure]);
  };

  const onSave = () => {
    updateSection({ id: section.id, structure }).then((res) => {
      if (res && res.data && res.data.id) {
        refetchSection();
        updatePostWithNewStructure(post, structure, section.id, postSlice, dispatch);
        showServerSuccess(i18n("toast.success_update"));
      } else {
        showServerError(res);
      }
    });
  };

  const addToEnd = (node) => {
    setModal({
      isOpen: true,
      inputType: node.type,
      onSubmit: (values) => {
        onUpdate([...structure, { ...node, ...values, id: shortid.generate() }]);
        setModal(null);
      },
      onClose: () => setModal(null),
    });
  };

  if (!active) return null;
  return (
    <>
      <div className={`fixed z-50 bottom-6 right-6 transition-all ${minimized ? "w-56 duration-75" : "w-full max-w-xl duration-300"}`}>
        <Draggable disabled={minimized} position={minimized ? { x: 0, y: 0 } : undefined} handle=".handle">
          <div className={`rounded-lg overflow-hidden shadow-lg`}>
            <div className={`handle py-2 pl-4 pr-2 bg-blue-500 text-white flex items-center justify-between ${minimized ? "w-56 duration-75" : "cursor-move w-full max-w-xl duration-300"}`}>
              <div>
                <button type="button" onClick={() => setMinimized(!minimized)} className="cursor-pointer">
                  {i18n("label.builder_tool")}
                </button>
              </div>
              <div className="flex items-center gap-4">
                {!minimized && structureChanged && (
                  <button
                    type="button"
                    onClick={onSave}
                    disabled={isLoading}
                    className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none transition-all duration-500 ease-in-out"
                  >
                    {i18n("button.save_changes")}
                  </button>
                )}
                <div>
                  {!minimized && (
                    <button type="button" onClick={() => setMinimized(true)} className="cursor-pointer hover:bg-white/10 p-3 rounded-full transition-all duration-500 ease-in-out">
                      <IconMinus className="w-5 h-5" />
                    </button>
                  )}
                  <button type="button" onClick={() => setActive(false)} className="cursor-pointer hover:bg-white/10 p-3 rounded-full transition-all duration-500 ease-in-out">
                    <IconX className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
            {!minimized && (
              <>
                <div className="bg-white p-4 pl-0 pb-8 h-80 relative">
                  <Dropzone data={structure} placeholder="Choose inputs" onUpdate={onUpdate} />
                  <div className="absolute bottom-4 right-4">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className=" text-white p-3 rounded-full bg-blue-500 hover:bg-blue-800 transition-all duration-300 shadow-lg">
                          <IconPlus />
                        </Menu.Button>
                      </div>
                      <Transition
                        as="div"
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-20 w-48 bg-white divide-y rounded-md shadow-xl py-1 px-2 h-80 divide-primary-50 ring-1 ring-text ring-opacity-5 focus:outline-none origin-bottom-right mb-2 right-0 bottom-full overflow-auto">
                          <div>
                            <h5 className="leading-relaxed py-2 text-sm">{i18n("label.list_inputs")}</h5>
                          </div>
                          <div className="flex flex-col gap-1 px-1 py-1">
                            {elements.map((item, index) => (
                              <Menu.Item key={String(index)}>
                                <button
                                  type="button"
                                  onClick={() => addToEnd(item)}
                                  className="flex w-full p-2 bg-gray-100 justify-between items-center text-sm rounded-md overflow-hidden mb-1 cursor-pointer transition-all duration-300 hover:bg-gray-200"
                                >
                                  <span>{item.title}</span>
                                  {item.icon}
                                </button>
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </>
            )}
          </div>
        </Draggable>
      </div>

      <ModalComponent {...modal} />
    </>
  );
}
