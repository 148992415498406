import { Content, Footer, Header, Struture, Tabs } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { cleanStructure } from "app/utils/content";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { components, mainTab, structureTab } from "./constants";
import Dropzone from "app/components/organisms/builder/Dropzone";
import { Menu, Transition } from "@headlessui/react";
import IconPlus from "app/components/atoms/icons/iconPlus";
import elements from "app/components/organisms/builder/helpers/elements";
import ModalComponent from "../modalComponent";
import shortid from "shortid";

export default function ModalComponentCustom(props) {
  const { isOpen = false, isCreate, isDrag, component, buttonText = i18n("label.edit_component"), validateKey, isLoading = false, onSubmit, onDelete, onClose = () => {} } = props;

  // STATE
  const [value, setValue] = useState(null);
  const [modal, setModal] = useState(null);
  const [tabs, setTabs] = useState([mainTab]);
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (isOpen) {
      const tempStructure = cleanStructure(components);
      // ON DRAG
      if (isDrag) {
        const tempStructure = cleanStructure(components);
        // KEY
        if (typeof validateKey !== "undefined") {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1) tempStructure[index].rules.push({ method: validateKey, validWhen: true, message: i18n("input.key_already_in_use"), temporary: true });
        }
      }
      // ON CREATE
      else if (component && isCreate) {
        setValue({ title: component.title });
        // REMOVE KEY FIELD ON CREATE
        const index = tempStructure.findIndex((item) => item.key === "key");
        if (index !== -1) tempStructure.splice(index, 1);
      }
      // ON EDIT
      else if (component) {
        // KEY
        if (typeof validateKey !== "undefined") {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1) tempStructure[index].rules.push({ method: validateKey, validWhen: true, message: i18n("input.key_already_in_use"), temporary: true });
        } else {
          const index = tempStructure.findIndex((item) => item.key === "key");
          if (index !== -1) tempStructure.splice(index, 1);
        }
        setValue({ title: component.title, children: component.children });
        setTabs([mainTab, structureTab]);
      }
      setStructure(tempStructure);
    } else {
      setValue(null);
      setTabs([mainTab]);
      setTabActive(0);
      setStructure(cleanStructure(components));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, component]);

  const onClickEnter = (code) => {
    if (code === 13) onClickSubmit();
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSubmit({ ...value });
    });
  };

  const addToEnd = (node) => {
    setModal({
      isOpen: true,
      isCreate: true,
      component: node,
      inputType: node.type,
      buttonText: i18n("label.update_component"),
      onSubmit: (values) => {
        setValue({
          ...value,
          children: [
            ...value?.children,
            {
              type: node.type,
              ...values,
              id: shortid.generate(),
            },
          ],
        });
        setModal(null);
      },
      onClose: () => setModal(null),
    });
  };

  const getTitle = () => {
    if (isDrag) {
      return i18n("label.edit_custom_component");
    } else if (isCreate) {
      return i18n("label.add_new_custom_component");
    } else {
      return i18n("label.edit_custom_component");
    }
  };

  return (
    <>
      <Struture isOpen={isOpen} onClose={onClose}>
        <Header title={getTitle()} onClose={onClose} />
        {tabs?.length > 1 && <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />}
        <Content tabs tab={tabActive} noPad>
          <div className="px-12 py-10">
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} onKeyDown={onClickEnter} />
          </div>
          <div className="relative pl-6 pr-12 py-10 h-[400px]">
            <Dropzone data={value?.children || []} placeholder="Choose inputs" onUpdate={(children) => setValue({ ...value, children })} />

            <div className="absolute bottom-4 right-4">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className=" text-white p-3 rounded-full bg-blue-500 hover:bg-blue-800 transition-all duration-300 shadow-lg">
                    <IconPlus />
                  </Menu.Button>
                </div>
                <Transition
                  as="div"
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-20 w-48 bg-white divide-y rounded-md shadow-xl py-1 px-2 h-80 divide-primary-50 ring-1 ring-text ring-opacity-5 focus:outline-none origin-bottom-right mb-2 right-0 bottom-full overflow-auto">
                    <div>
                      <h5 className="leading-relaxed py-2 text-sm">{i18n("label.list_inputs")}</h5>
                    </div>
                    <div className="flex flex-col gap-1 px-1 py-1">
                      {elements.map((item, index) => (
                        <Menu.Item key={String(index)}>
                          <button
                            type="button"
                            onClick={() => addToEnd(item)}
                            className="flex w-full p-2 bg-gray-100 justify-between items-center text-sm rounded-md overflow-hidden mb-1 cursor-pointer transition-all duration-300 hover:bg-gray-200"
                          >
                            <span>{item.title}</span>
                            {item.icon}
                          </button>
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </Content>
        <Footer
          loading={isLoading}
          cancel={{ text: i18n("button.close"), onClick: onClose }}
          clean={{ text: i18n("button.delete"), onClick: onDelete }}
          submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }}
        />
      </Struture>
      <ModalComponent {...modal} />
    </>
  );
}
