import { i18n } from "app/i18n";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

// DND KIT
import { SortableContext, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, pointerWithin, rectIntersection, useSensor, useSensors } from "@dnd-kit/core";

// COMPONENTS
import Sidebar from "./sidebar";
import elements from "./elements";
import Droppable from "./components/droppable";
import BuilderHeader from "./components/header";
import SortableTreeItem from "./components/item";
import ModalComponent from "app/components/molecules/modals/modalComponent";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalComponentCustom from "app/components/molecules/modals/modalComponentCustom";

// UTILS
import { getRandomString, getSource } from "app/utils/content";
import { isKeyAvailableInArray } from "../builder/helpers";
import {
  adjustTranslate,
  buildTree,
  dropAllowedInputs,
  dropAnimationConfig,
  flatten,
  flattenTree,
  getChildCount,
  getProjection,
  measuring,
  removeChildrenOf,
  removeItem,
  setProperty,
  sortableTreeKeyboardCoordinates,
} from "./utilities";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import { useUpdateProjectMutation } from "app/stores/project";
import { useParams } from "react-router-dom";
import { prepareCustomInputs } from "app/utils/visual";
import { useSelector } from "react-redux";

export default function SortableTree(props) {
  const {
    id,
    slug,
    title,
    onSave,
    isError,
    children,
    isLoading,
    initialItems,
    isSection,
    isTemplate,
    isEditCustom,
    removable = true,
    indicator = false,
    collapsible = true,
    editable = true,
    duplicable = true,
    indentationWidth = 30,
    placeholder = i18n("label.drop_components_here"),
  } = props;

  //ROUTER
  let { project } = useParams();

  // STATE
  const [items, setItems] = useState([]);
  const [overId, setOverId] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [sidebarItem, setSidebarItem] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [modalConfirm, setModalConfirm] = useState(null);
  const [modalComponent, setModalComponent] = useState(null);
  const [modalCustom, setModalCustom] = useState(null);
  const [modalPreview, setModalPreview] = useState(null);
  const [sidebarRegKey, setSidebarRegKey] = useState(dayjs());
  const [validDropTargets, setValidDropTargets] = useState([]);

  //STORE
  const { inputs } = useSelector((store) => store.builder);

  //MUTATION
  const [updateProject] = useUpdateProjectMutation();

  // REF
  const prevItems = useRef(items);
  const refContainer = useRef();

  // ITEMS BUILD TREE
  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree([...items, ...sidebarItem]);
    const collapsedItems = flattenedTree.reduce((acc, { children, collapsed, id, customId }) => ((collapsed && children.length) || customId ? [...acc, id] : acc), []);
    return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId, items, sidebarItem]);

  // ITEMS CONTEXT
  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;
  const projected = activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth) : null;

  // SENSORS
  const sensorContext = useRef({ items: flattenedItems, offset: offsetLeft });
  const [coordinateGetter] = useState(() => sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth));
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    }),
    useSensor(KeyboardSensor, { coordinateGetter })
  );

  useEffect(() => {
    sensorContext.current = { items: [...flattenedItems, ...sidebarItem, ...inputs], offset: offsetLeft };
  }, [flattenedItems, sidebarItem, inputs, offsetLeft]);

  // EFFECT TO REINITIALIZE ITEMS ON INITIAL_ITEMS CHANGE
  useEffect(() => {
    if (JSON.stringify(initialItems) !== JSON.stringify(items)) {
      setItems(initialItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItems]);

  function toggleSidebar() {
    setSidebarOpen(!sidebarOpen);
  }

  function onUpdateStructure(structure) {
    setItems(structure);
    if (prevItems.current !== structure) {
      onSave(structure);
      prevItems.current = structure;
    }
  }

  return (
    <>
      <DndContext
        autoScroll
        sensors={sensors}
        measuring={measuring}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={pointerWithin}
        // collisionDetection={rectIntersection}
      >
        <div className="flex flex-row h-full flex-1">
          {/* BUILDER */}
          <div className=" flex flex-col flex-1">
            {/* HEADER */}
            <BuilderHeader title={title} slug={slug} isLoading={isLoading} isError={isError} children={children} isEditCustom={isEditCustom} />
            {/* TREE */}
            <div className="p-8  flex-1 overflow-auto max-h-full" ref={refContainer}>
              <SortableContext id={id} items={sortedIds} strategy={rectSortingStrategy}>
                <Droppable
                  key={id}
                  id="main"
                  disabled={!items?.length || !validDropTargets.includes(id)}
                  className={`flex flex-col gap-1 p-1 ${!validDropTargets.includes(id) ? "opacity-50 pointer-events-none" : ""}`}
                >
                  {flattenedItems.map(({ id, children, collapsed, depth, customId, ...data }) => (
                    <SortableTreeItem
                      {...data}
                      key={id}
                      id={id}
                      slug={data?.key}
                      customId={customId}
                      isTemplate={isTemplate}
                      isSection={isSection}
                      indicator={indicator}
                      indentationWidth={indentationWidth}
                      collapsed={Boolean(collapsed && children.length)}
                      isValidDropTarget={validDropTargets.includes(id)}
                      onEdit={editable ? () => handleEdit(id) : undefined}
                      onCustom={isSection && children?.length ? () => handleCustom(id) : undefined}
                      onRemoveCustom={isSection ? () => handleRemoveCustom(id) : undefined}
                      onRemove={removable ? () => handleRemove(id) : undefined}
                      onDuplicate={duplicable ? () => handleDuplicate(id) : undefined}
                      depth={id === activeId && projected ? projected.depth : depth}
                      onCollapse={collapsible && children?.length ? () => handleCollapse(id) : undefined}
                    />
                  ))}
                </Droppable>

                {!items?.length && (
                  <Droppable id="firstDrop">
                    <span className="text-xl xl:text-3xl text-slate-300 italic">{placeholder}</span>
                  </Droppable>
                )}

                {createPortal(
                  <DragOverlay dropAnimation={dropAnimationConfig} modifiers={indicator ? [adjustTranslate] : undefined}>
                    {activeId && activeItem ? (
                      <SortableTreeItem
                        clone
                        {...activeItem}
                        id={activeId}
                        customId={activeId.customId}
                        isTemplate={isTemplate}
                        isSection={isSection}
                        slug={activeItem.key}
                        depth={activeItem.depth}
                        indentationWidth={indentationWidth}
                        childCount={getChildCount(items, activeId) + 1}
                      />
                    ) : null}
                  </DragOverlay>,
                  document.body
                )}
              </SortableContext>
            </div>
          </div>
          {/* SIDEBAR */}

          <div className={`relative peer text-sm p-4 flex flex-col border-l ${sidebarOpen ? "w-60" : "w-0 px-0"} transition-all duration-500 ease-in-out overflow-auto`}>
            {/*             <button
              type="button"
              onClick={toggleSidebar}
              className="block xl:hidden absolute -left-3 top-0 bottom-0 m-auto w-fit py-[2px] px-4 h-fit -rotate-90 origin-left text-sm border z-10 font-xs rounded-t-md bg-primary-900 text-white hover:bg-primary-500 transition-all duration-300 ease-in-out"
            >
              {sidebarOpen ? i18n("button.hide") : i18n("button.show")}
            </button> */}
            <div className="flex-1 relative">
              <Sidebar regKey={sidebarRegKey} onClick={handleAddToEnd} showThumbnail={onShowThumbnail} onEditCustom={onEditCustom} isTemplate={isTemplate} isSection={isSection} />
            </div>
          </div>
        </div>
      </DndContext>

      <ModalComponentCustom {...modalCustom} />
      <ModalPreview {...modalPreview} />
      <ModalComponent {...modalComponent} />
      <ModalConfirm {...modalConfirm} />
    </>
  );

  function handleDragStart({ active }) {
    const activeId = active?.id;
    setActiveId(activeId);
    setOverId(activeId);
    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove(props) {
    const clonedItems = JSON.parse(JSON.stringify(flattenedItems));

    const overId = props?.over?.id;
    const activeId = props?.active?.id;

    // Encontrar índices do item ativo e do item sobre o qual está
    const activeIndex = clonedItems.findIndex((item) => item.id === activeId);
    const itemIndex = clonedItems.findIndex((item) => item.id === overId);

    // FIRST ELEMENT OF LIST (NO PARENT)
    if (itemIndex <= 0) {
      setOffsetLeft(0);
      return;
    }

    // Determinar o item a considerar
    let prevItem;

    if (itemIndex < activeIndex) {
      // Quando o item está acima da posição inicial
      prevItem = clonedItems[itemIndex - 1];
    } else if (itemIndex === activeIndex) {
      prevItem = clonedItems[itemIndex - 1];
    } else {
      // Quando o item está abaixo da posição inicial
      prevItem = clonedItems[itemIndex];
    }

    // ALLOWED
    if (prevItem && !prevItem.customId && dropAllowedInputs.includes(prevItem.type)) {
      // console.log("########### ALLOWED DROP ###########");

      // FORCE INDENTATION
      const maxIndentation = indentationWidth * (prevItem.depth + 1);
      // console.log({ maxIndentation, x: props.delta.x, math: Math.min(props.delta.x, maxIndentation) });
      setOffsetLeft(Math.min(props.delta.x, maxIndentation));
      return;
    }
    // DISALLOWED
    else {
      if (prevItem && typeof prevItem.depth === "number") {
        // console.log("/////////// DISALLOWED DROP ///////////");
        const maxIndentation = indentationWidth * prevItem.depth;
        setOffsetLeft(Math.min(props.delta.x, maxIndentation));
      } else {
        setOffsetLeft(0);
      }
      return;
    }

    // setOffsetLeft(props.delta.x);
    // return;
  }

  function handleDragOver({ active, over }) {
    const overId = over?.id ?? null;
    const activeData = active?.data?.current ?? {};
    const { isSidebar, isSection, title, customId, children, key } = activeData;

    setOverId(overId);

    if (isSection) {
      setSidebarItem([{ id: activeId, index: 0, depth: 0, title: title, children: children, customId: customId }]);
    }

    if (!isSidebar) return;

    const input = elements.find((e) => e.type === key);

    if (!input) return;

    setSidebarItem([{ ...input, index: 0, depth: 0, id: activeId, children: children, customId: customId }]);
  }

  function handleDragEnd({ active, over }) {
    resetState();
    const overId = over?.id;
    const isSidebarItem = sidebarItem?.length;
    const customId = sidebarItem[0]?.customId || null;
    const children = sidebarItem[0]?.children || null;

    // FIRST ELEMENT
    if (overId === "firstDrop") {
      const id = getRandomString(6, false);
      placeInput(
        { ...sidebarItem[0] },
        (data) => {
          if (isTemplate) {
            onUpdateStructure(buildTree([{ ...data, id, idSection: data.id }]));
          } else if (isSection && customId) {
            onUpdateStructure(buildTree([...flattenTree([{ ...data, id, customId, children: addParentToChildren(children, id) }])]));
          } else {
            onUpdateStructure(buildTree([{ ...data, id }]));
          }
        },
        () => {
          return;
        }
      );

      return;
    }

    if (!over) return;
    // OTHERS
    const { depth, parentId } = projected || { depth: 0, parentId: null };
    // SEQUENCE
    const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));

    // CHECK IF PARENT IF LIST OR GROUP (ALLOW CHILDREN);
    const parentItem = clonedItems.find((item) => item.id === parentId);
    if (parentItem && !dropAllowedInputs.includes(parentItem.type)) {
      console.error("Não é permitido inserir neste item.");
      return;
    }

    // IF IS A NEW ITEM FROM SIDEBAR
    if (isSidebarItem) {
      let index = over?.data?.current?.sortable?.index;
      if (typeof index === "undefined") index = clonedItems.length;

      const sortedItems = clonedItems;
      const id = getRandomString(6, false);

      // TEMPLATE
      if (isTemplate) {
        placeInput({ ...sidebarItem[0] }, (data) => {
          sortedItems.splice(index, 0, { ...data, id, idSection: data.id, depth, parentId });
          onUpdateStructure(buildTree(sortedItems));
        });
        return;
      }
      // CUSTOM INPUTS
      else if (isSection && customId) {
        placeInput({ ...sidebarItem[0] }, (data) => {
          const flat = flatten([{ ...data, id, customId, children: addParentToChildren(children, id) }], parentId, depth);
          sortedItems.splice(index, 0, ...flat);

          onUpdateStructure(buildTree([...sortedItems]));
        });
        return;
      }
      // DEFAULT
      placeInput({ ...sidebarItem[0] }, (data) => {
        sortedItems.splice(index, 0, { ...data, id, depth, parentId });
        onUpdateStructure(buildTree(sortedItems));
      });

      return;
    }

    const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
    const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);

    // VERIFY KEYS
    const activeItem = clonedItems[activeIndex];

    const keyInUse = () => {
      setModalConfirm({
        isOpen: true,
        type: "CANCEL",
        title: i18n("label.key_in_use"),
        text: i18n("alert.key_in_use"),
        buttonText: i18n("button.back"),
        disableCancel: true,
        onConfirm: () => setModalConfirm(null),
      });
    };

    // IF NO PARENT
    if (!parentId) {
      const keyAvailable = isKeyAvailableInArray(null, getComponentsAtMainLevel(), activeItem.key, activeItem.id);
      if (!keyAvailable) return keyInUse();
    }
    // IF HAS PARENT
    else if (activeItem?.parentId !== parentId) {
      const parent = clonedItems.find((item) => item.id === parentId);
      const keyAvailable = isKeyAvailableInArray(parent, undefined, activeItem.key);
      if (!keyAvailable) return keyInUse();
    }

    const activeTreeItem = clonedItems[activeIndex];
    clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

    const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
    const newItems = buildTree(sortedItems);
    onUpdateStructure(newItems);
  }

  function placeInput(item, onSuccess, onCancel) {
    if (!item?.type) return onSuccess(item);

    // TEMPLATE
    if (isTemplate) return onSuccess({ type: item.type });

    const handleSubmit = (data) => {
      if (onSuccess) onSuccess({ type: item.type, ...data });
      setModalCustom(null);
      setModalComponent(null);
    };

    const handleClose = () => {
      if (onCancel) onCancel();
      setModalCustom(null);
      setModalComponent(null);
    };

    // CUSTOM INPUT
    if (isSection && item.customId) {
      setModalCustom({
        isOpen: true,
        isCreate: true,
        buttonText: i18n("button.create"),
        validateKey: (key) => isKeyAvailableInArray(null, getComponentsAtSameLevel(item), key),
        onSubmit: handleSubmit,
        onClose: handleClose,
      });
      return;
    }

    setModalComponent({
      isOpen: true,
      inputType: item.type,
      buttonText: i18n("label.add_component"),
      inputs: getComponentsAtSameLevel(item),
      validateKey: (key) => isKeyAvailableInArray(null, getComponentsAtSameLevel(item), key),
      onSubmit: handleSubmit,
      onClose: handleClose,
    });
  }

  function getComponentsAtSameLevel({ depth = 0, id }) {
    return flattenedItems.filter((item) => item.depth === depth && item.id !== id);
  }

  function getComponentsAtMainLevel() {
    return flattenedItems.filter((item) => item.depth === 0);
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setSidebarItem([]);
    setValidDropTargets([]);
    setSidebarRegKey(dayjs());

    document.body.style.setProperty("cursor", "");
  }

  function handleRemove(id) {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.delete_field"),
      text: i18n("alert.action_delete"),
      onConfirm: () => {
        const newList = removeItem(items, id);
        onUpdateStructure(newList);
        setModalConfirm(null);
      },
      onClose: () => {
        setModalConfirm(null);
      },
    });
  }

  function handleEdit(id) {
    // SEQUENCE
    const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
    const item = clonedItems.find((item) => item.id === id);
    const index = clonedItems.findIndex((item) => item.id === id);

    if (item && index !== -1) {
      setModalComponent({
        isOpen: true,
        component: item,
        buttonText: i18n("label.update_component"),
        inputType: item?.type,
        inputs: getComponentsAtSameLevel(item),
        validateKey: (key) => isKeyAvailableInArray(null, getComponentsAtSameLevel(item), key, item.id),
        onSubmit: (data) => {
          clonedItems[index] = { ...item, ...data };
          const newItems = buildTree(clonedItems);
          onUpdateStructure(newItems);
          setModalComponent(null);
        },
        onClose: () => {
          setModalComponent(null);
        },
      });
    }
  }

  function handleDuplicate(id) {
    const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
    const item = clonedItems.find((item) => item.id === id);
    const index = clonedItems.findIndex((item) => item.id === id);

    const baseKey = item.key.includes("-copy") ? item.key : item.key;

    const existingClones = clonedItems.filter((clonedItem) => clonedItem.key.startsWith(`${baseKey}-copy`));

    const cloneSuffixes = existingClones
      .map((clonedItem) => {
        const match = clonedItem.key.match(/-copy(\d+)$/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((suffix) => suffix !== null);

    let nextSuffix = 1;
    while (cloneSuffixes.includes(nextSuffix)) {
      nextSuffix++;
    }
    const newKey = `${baseKey}-copy${nextSuffix}`;

    const newId = getRandomString(6, false);
    const newItem = {
      ...item,
      key: newKey,
      id: newId,
    };

    // IMPORTANT TO AVOID DUPLICATED IDS
    if (item?.children?.length) {
      newItem.children = addParentToChildren(item.children, newId);
    }
    // BUILD FLATTEN
    const flattenNewInputs = [newItem, ...flatten(newItem.children, newId, item.depth)];

    // ADD TO LIST
    const sortedItems = clonedItems;
    sortedItems.splice(index + 1, 0, ...flattenNewInputs);

    onUpdateStructure(buildTree(sortedItems));
  }

  function handleCollapse(id) {
    setItems((items) =>
      setProperty(items, id, "collapsed", (value) => {
        return !value;
      })
    );
  }

  function handleAddToEnd(input) {
    placeInput(
      input,
      (data) => {
        const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
        const sortedItems = clonedItems;
        const customId = input.customId ? input.customId : null;
        const children = input.children ? input.children : null;
        const id = getRandomString(6, false);

        if (isTemplate) {
          sortedItems.push({ type: input.type, ...data, id, idSection: input.id, depth: 0, parentId: null });
          onUpdateStructure(buildTree(sortedItems));
        } else if (isSection && customId) {
          onUpdateStructure(buildTree([...sortedItems, ...flattenTree([{ type: input.type, ...data, id, depth: 0, parentId: null, customId, children: addParentToChildren(children, id) }])]));
        } else {
          sortedItems.push({ type: input.type, ...data, id, depth: 0, parentId: null });
          onUpdateStructure(buildTree(sortedItems));
        }
      },
      () => {
        return;
      }
    );
  }

  function addParentToChildren(children, parentId) {
    if (!children?.length) return [];
    return children.map((child) => ({
      ...child,
      id: getRandomString(6, false),
      parentId,
      children: addParentToChildren(child.children, child.id),
    }));
  }

  //CREATE CUSTOM INPUT
  function handleCustom(id) {
    const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));

    const index = clonedItems.findIndex((item) => item.id === id);
    const customId = getRandomString(6, false);

    setModalCustom({
      isOpen: true,
      isCreate: true,
      buttonText: i18n("button.create"),
      component: clonedItems[index],
      onClose: () => setModalCustom(null),
      onSubmit: (values) => {
        if (inputs.some((input) => input.title === values.title)) {
          setModalConfirm({
            isOpen: true,
            type: "CANCEL",
            title: i18n("label.alredy_exists"),
            text: i18n("alert.action_already_exists"),
            buttonText: i18n("button.back"),
            disableCancel: true,
            onConfirm: () => setModalConfirm(null),
          });
          return;
        }

        const newItem = { title: values.title, type: clonedItems[index].type, children: clonedItems[index].children, id: customId };
        const newCustomInputs = prepareCustomInputs(inputs, newItem);

        if (newCustomInputs?.length) {
          updateProject({ id: project, customInputs: newCustomInputs }).then((res) => {});
        }

        const updatedStructure = clonedItems.map((el) => {
          if (el.id === id) {
            return { ...el, customId: customId };
          }
          return el;
        });

        onUpdateStructure(buildTree(updatedStructure));
        setModalCustom(null);
      },
    });
  }

  // REMOVE CUSTOM INPUT
  function handleRemoveCustom(id) {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.disconnect_custom"),
      text: i18n("alert.action_remove_custom"),
      onClose: () => setModalConfirm(null),
      onConfirm: () => {
        const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
        const updatedStructure = clonedItems.map((el) => {
          if (el.id === id) {
            const { customId, ...rest } = el;
            return rest;
          }
          return el;
        });
        onUpdateStructure(buildTree(updatedStructure));
        setModalConfirm(null);
      },
    });
  }

  // SHOW THUMBNAIL
  function onShowThumbnail(href) {
    setModalPreview({
      isOpen: true,
      href: getSource(href),
      onClose: () => setModalPreview(null),
    });
  }

  // EDIT CUSTOM INPUT
  function onEditCustom(item) {
    setModalCustom({
      isOpen: true,
      component: item,
      buttonText: i18n("button.update"),
      onClose: () => setModalCustom(null),
      onDelete: () => {
        setModalConfirm({
          isOpen: true,
          type: "DELETE",
          title: i18n("label.delete_field"),
          text: i18n("alert.action_delete"),
          onConfirm: () => {
            // REMOVE CUSTOM INPUT
            const customId = item.id;

            function removeCustomItem(inputs, customId) {
              return inputs.filter((input) => input.id !== customId);
            }

            const newCustomInputs = removeCustomItem(inputs, customId);

            if (newCustomInputs?.length) {
              updateProject({ id: project, customInputs: newCustomInputs }).then((res) => {
                console.log("res", res);
              });
            }

            // REMOVE CUSTOM INPUT FROM STRUCTURE
            const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));

            function updateStructure(items, customId) {
              return items.map((item) => {
                if (item.customId === customId) {
                  const { customId, ...rest } = item;
                  return rest;
                }
                return item;
              });
            }

            const updatedStructure = updateStructure(clonedItems, customId);
            onUpdateStructure(buildTree(updatedStructure));

            setModalConfirm(null);
            setModalCustom(null);
          },
          onClose: () => {
            setModalConfirm(null);
          },
        });
      },
      onSubmit: (data) => {
        // UPDATE CUSTOM INPUTS ON PROJECT
        const customInputs = prepareCustomInputs(inputs, { ...item, ...data });
        if (customInputs?.length) {
          updateProject({ id: project, customInputs }).then((res) => {
            if (!res?.data) return;
          });
        }

        // UPDATE CURRENT SECTION IF HAS CUSTOM INPUT
        const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
        const flattenNewItems = flattenTree(data.children);

        function findCustomItemIds(items, customId) {
          let result = [];

          for (const item of items) {
            if (item.customId === customId) {
              result.push(item.id);
            }
            if (item.children) {
              result = result.concat(findCustomItemIds(item.children, customId));
            }
          }

          return result;
        }

        function replaceCustomItems(items, customId) {
          return items.map((item) => {
            if (item.customId === customId) {
              return { ...item, children: data.children };
            }
            if (item.children) {
              return { ...item, children: replaceCustomItems(item.children, customId) };
            }
            return item;
          });
        }

        function hasAncestorWithCustomId(item, customId, itemsMap) {
          let currentItem = item;
          while (currentItem.parentId) {
            if (currentItem.parentId === customId) {
              return true;
            }
            currentItem = itemsMap[currentItem.parentId];
          }
          return false;
        }

        const customInputsInCurrentSection = findCustomItemIds(clonedItems, item.id);
        // CASO EXISTA O CUSTOM INPUT NA ATUAL SECÇÂO
        if (customInputsInCurrentSection?.length) {
          // FASE 001 - ATUALIZER NOS CUSTOM
          let updatedStructure = replaceCustomItems(clonedItems, item.id);

          // Criar um mapa de itens para acesso rápido
          const itemsMap = updatedStructure.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});

          // FASE 002 - ATUALIZAR OS FILHOS
          for (const customId of customInputsInCurrentSection) {
            // CLEAN OLD
            updatedStructure = updatedStructure.filter((e) => e.parentId !== customId && !hasAncestorWithCustomId(e, customId, itemsMap));

            // ADD NEW STRUCTURE
            const index = updatedStructure.findIndex((e) => e.id === customId);
            if (index !== -1) {
              const depth = updatedStructure[index].depth;
              updatedStructure.splice(index + 1, 0, ...flattenNewItems?.map((el) => ({ ...el, depth: depth + el.depth + 1, parentId: el?.parentId || customId })));
            }
          }
          onUpdateStructure(buildTree(updatedStructure));
        }

        setModalCustom(null);
      },
    });
  }
}
