import React, { useEffect, useState } from "react";
import { i18n } from "app/i18n";

// Atoms
import { Struture, Header, Content, Footer } from "../../../atoms/modal";

// Organisms
import Form from "app/components/organisms/form";

// Helpers
import { validation } from "app/utils/validators";
import { components } from "./constants";
import { cleanStructure } from "app/utils/content";

// Store
import { useSelector } from "react-redux";

export default function ModalContentType(props) {
  const { isOpen = false, contentType, buttonText = i18n("button.create_contentType"), onSubmit, isLoading, onClose = () => {} } = props;

  // STORE
  const { user } = useSelector((store) => store.auth);
  const { project } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const tempStructure = cleanStructure(components);

      // KEY
      const index = tempStructure.findIndex((item) => item.key === "key");
      if (index !== -1) {
        if (contentType) tempStructure[index].rules[1].args.id = contentType.id;

        // PROJECT (REQUIRED)
        if (project?.id) tempStructure[index].rules[1].args.project = project.id;
      }

      setStructure([...tempStructure]);
    }
  }, [isOpen, contentType, project, user]);

  useEffect(() => {
    if (contentType) {
      const tempValue = { ...contentType };
      setValue({ ...tempValue });
    }
  }, [contentType, user, project]);

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSubmit(value);
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={contentType ? i18n("label.edit_contentType") : i18n("label.create_contentType")} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
