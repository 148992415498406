import { i18n } from "app/i18n";
import validator from "validator";

export const mainTab = { name: i18n("label.input") };
export const structureTab = { name: i18n("label.structure") };

export const components = [
  {
    type: "Text",
    key: "title",
    title: "Title",
    onBlur: { key: "key", lower: true, replacement: "", removeExtra: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Slug",
    key: "key",
    title: "Key",
    options: { fullRemove: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
];
