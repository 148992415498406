import { closestCorners, defaultDropAnimation, getFirstCollision, KeyboardCode, MeasuringStrategy } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const directions = [KeyboardCode.Down, KeyboardCode.Right, KeyboardCode.Up, KeyboardCode.Left];
const horizontal = [KeyboardCode.Left, KeyboardCode.Right];

export const dropAllowedInputs = ["List", "Group"];
export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

export const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

export const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

export const animateLayoutChanges = ({ isSorting, wasDragging }) => (isSorting || wasDragging ? false : true);

export const dropAnimationConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

function getDragDepth(offset, indentationWidth) {
  return Math.round(offset / indentationWidth);
}

export function getProjection(items, activeId, overId, dragOffset, indentationWidth) {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);

  if (activeItemIndex === -1 || overItemIndex === -1) return;

  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);

  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];

  // CALCULATE NEW DEPTH BASED ON DRAG OFFSET
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;

  // LIMIT DEPTH BETWEEN MAX AND MIN
  const maxDepth = getMaxDepth({ previousItem, activeItem });
  const minDepth = getMinDepth({ nextItem });
  let depth = Math.min(Math.max(projectedDepth, minDepth), maxDepth);
  // RETURN NEW DEPTH AND PARENT ID
  return { depth, maxDepth, minDepth, parentId: getParentId(depth) };

  function getParentId(depth) {
    if (depth === 0 || !previousItem) return null;

    if (depth === previousItem.depth) return previousItem.parentId;
    if (depth > previousItem.depth) return previousItem.id;

    return (
      newItems
        .slice(0, overItemIndex)
        .reverse()
        .find((item) => item.depth === depth)?.id ?? null
    );
  }
}

function getMaxDepth({ previousItem }) {
  if (!previousItem || previousItem.customId) return 0;
  return dropAllowedInputs.includes(previousItem.type) ? previousItem.depth + 1 : previousItem.depth;
}

function getMinDepth({ nextItem }) {
  return nextItem ? nextItem.depth : 0;
}

export function flatten(items, parentId = null, depth = 0) {
  if (!items?.length) return [];
  return items.reduce((acc, item, index) => {
    return [...acc, { ...item, parentId, depth, index }, ...flatten(item.children, item.id, depth + 1)];
  }, []);
}

export function flattenTree(items) {
  return flatten(items);
}

export function buildTree(flattenedItems) {
  const root = { id: "root", children: [] };
  const nodes = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { id, children } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);
    nodes[id] = { id, children };
    parent.children.push(item);
  }

  return root.children;
}

export function findItem(items, itemId) {
  return items.find(({ id }) => id === itemId);
}

export function findItemDeep(items, itemId) {
  for (const item of items) {
    const { id, children } = item;
    if (id === itemId) return item;
    if (children?.length) {
      const child = findItemDeep(children, itemId);
      if (child) return child;
    }
  }
  return undefined;
}

export function removeItem(items, id) {
  const newItems = [];
  for (const item of items) {
    let el = { ...item };
    if (el?.id === id) continue;
    if (el?.children?.length) el.children = removeItem(el.children, id);
    newItems.push(el);
  }
  return newItems;
}

export function setProperty(items, id, property, setter) {
  const updatedItems = items.map((item) => {
    if (item.id === id) {
      return { ...item, [property]: setter(item[property]) };
    }
    if (item.children.length) {
      return { ...item, children: setProperty(item.children, id, property, setter) };
    }
    return item;
  });
  return updatedItems;
}

function countChildren(items, count = 0) {
  return items?.reduce((acc, { children }) => {
    if (children?.length) return countChildren(children, acc + 1);
    return acc + 1;
  }, count);
}

export function getChildCount(items, id) {
  const item = findItemDeep(items, id);
  return item ? countChildren(item.children) : 0;
}

export function removeChildrenOf(items, ids) {
  const excludeParentIds = [...ids];
  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) excludeParentIds.push(item.id);
      return false;
    }
    return true;
  });
}

export const sortableTreeKeyboardCoordinates =
  (context, indicator, indentationWidth) =>
  (event, { currentCoordinates, context: { active, over, collisionRect, droppableRects, droppableContainers } }) => {
    if (directions.includes(event.code)) {
      if (!active || !collisionRect) {
        return;
      }

      event.preventDefault();

      const {
        current: { items, offset },
      } = context;

      if (horizontal.includes(event.code) && over?.id) {
        const { depth, maxDepth, minDepth } = getProjection(items, active.id, over.id, offset, indentationWidth);

        switch (event.code) {
          case KeyboardCode.Left:
            if (depth > minDepth) {
              return {
                ...currentCoordinates,
                x: currentCoordinates.x - indentationWidth,
              };
            }
            break;
          case KeyboardCode.Right:
            if (depth < maxDepth) {
              return {
                ...currentCoordinates,
                x: currentCoordinates.x + indentationWidth,
              };
            }
            break;
          default:
            break;
        }

        return undefined;
      }

      const containers = [];

      droppableContainers.forEach((container) => {
        if (container?.disabled || container.id === over?.id) {
          return;
        }

        const rect = droppableRects.get(container.id);

        if (!rect) {
          return;
        }

        switch (event.code) {
          case KeyboardCode.Down:
            if (collisionRect.top < rect.top) {
              containers.push(container);
            }
            break;
          case KeyboardCode.Up:
            if (collisionRect.top > rect.top) {
              containers.push(container);
            }
            break;
          default:
            break;
        }
      });

      const collisions = closestCorners({
        active,
        collisionRect,
        pointerCoordinates: null,
        droppableRects,
        droppableContainers: containers,
      });
      let closestId = getFirstCollision(collisions, "id");

      if (closestId === over?.id && collisions.length > 1) {
        closestId = collisions[1].id;
      }

      if (closestId && over?.id) {
        const activeRect = droppableRects.get(active.id);
        const newRect = droppableRects.get(closestId);
        const newDroppable = droppableContainers.get(closestId);

        if (activeRect && newRect && newDroppable) {
          const newIndex = items.findIndex(({ id }) => id === closestId);
          const newItem = items[newIndex];
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const activeItem = items[activeIndex];

          if (newItem && activeItem) {
            const { depth } = getProjection(items, active.id, closestId, (newItem.depth - activeItem.depth) * indentationWidth, indentationWidth);
            const isBelow = newIndex > activeIndex;
            const modifier = isBelow ? 1 : -1;
            const offset = indicator ? (collisionRect.height - activeRect.height) / 2 : 0;

            const newCoordinates = {
              x: newRect.left + depth * indentationWidth,
              y: newRect.top + modifier * offset,
            };

            return newCoordinates;
          }
        }
      }
    }

    return undefined;
  };
