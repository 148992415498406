import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Atoms
import { Struture, Header, Content, Footer, Tabs } from "../../../atoms/modal";

// Organisms
import Form from "app/components/organisms/form";

// Helpers
import { validation } from "app/utils/validators";
import { components, tabs } from "./constants";
import { cleanStructure } from "app/utils/content";
import { i18n } from "app/i18n";

export default function ModalSection(props) {
  const { isOpen = false, section, buttonText = i18n("button.create_section"), onSubmit, isLoading, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [tabActive, setTabActive] = useState(0);
  const [structure, setStructure] = useState(null);

  const { project } = useSelector((store) => store.project);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setTabActive(0);
      setStructure(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (section) setValue({ ...section });
    const tempStructure = cleanStructure([...components]);
    const index = tempStructure.findIndex((item) => item.key === "key");
    if (index !== -1) {
      // EXISTING SECTION
      if (section?.id) tempStructure[index].rules[1].args.id = section.id;
      // PROJECT (REQUIRED)
      if (project?.id) tempStructure[index].rules[1].args.project = project.id;
    }
    setStructure([...tempStructure]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, project, isOpen]);

  const onClickEnter = (code) => {
    if (code === 13) onClickSubmit();
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSubmit({ ...value, thumbnail: value.thumbnail ? { connect: { id: value.thumbnail.id } } : undefined });
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={section ? i18n("label.edit_section") : i18n("label.create_new_section")} onClose={onClose} />
      <Tabs tabs={tabs} active={tabActive} onChange={setTabActive} />
      <Content tabs tab={tabActive}>
        {tabs.map((tab, index) => (
          <div key={index}>
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} tabMode tab={tabActive} onKeyDown={onClickEnter} />
          </div>
        ))}
      </Content>
      <Footer
        loading={isLoading}
        cancel={{
          text: i18n("button.close"),
          onClick: onClose,
        }}
        submit={{
          text: buttonText,
          disabled: isLoading,
          onClick: onClickSubmit,
        }}
      />
    </Struture>
  );
}
