import validator from "validator";
import customValidator from "app/utils/validators/customValidator";
import { i18n } from "app/i18n";

const tabs = [{ name: i18n("label.section") }, { name: i18n("label.preview") }, { name: i18n("label.templates") }];

const components = [
  {
    tab: 0,
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    onBlur: { key: "key", lower: false },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: 0,
    type: "Text",
    key: "key",
    title: i18n("label.key"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isSectionKeyAvailable,
        validWhen: true,
        args: { id: undefined, project: undefined },
        message: i18n("input.key_already_in_use"),
      },
    ],
  },
  {
    tab: 1,
    type: "Multimedia",
    key: "thumbnail",
    noAlt: true,
    title: i18n("label.preview"),
  },
];

export { tabs, components };
