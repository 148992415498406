import Loading from "app/components/atoms/loading";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPreview from "app/components/molecules/modals/modalPreview";
import ModalTemplate from "app/components/molecules/modals/modalTemplate";
import SortableTree from "app/components/organisms/buildernew";
import History from "app/components/organisms/buildernew/components/history";
import { i18n } from "app/i18n";
import { setActiveHistory, useDeleteTemplateMutation, useGetTemplateQuery, useUpdateTemplateMutation } from "app/stores/builder";
import { getSource } from "app/utils/content";
import { showServerError } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { builderApi } from "app/stores/builder";
import ModalUsageLocation from "app/components/molecules/modals/modalUsageLocation";

export default function TemplatesEdit(props) {
  const { reloadList, onDuplicate } = props;

  // ROUTER
  const navigate = useNavigate();
  let { project, id } = useParams();

  // STATE
  const [timer, setTimer] = useState(null);
  const [modal, setModal] = useState(null);
  const [sections, setSections] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [previewModal, setPreviewModal] = useState(null);
  const [modalUsageLocation, setModalUsageLocation] = useState(null);

  // STORE
  const dispatch = useDispatch();
  const { active, history } = useSelector((store) => store.builder);

  // QUERY
  let { data, isLoading: isGetting, isFetching, isUninitialized, isError: isErrorGet } = useGetTemplateQuery({ id, project }, { skip: !id || !project, refetchOnReconnect: true });
  // MUTATION
  const [updateTemplate, { isLoading: isUpdating, isError: isErrorUpdate }] = useUpdateTemplateMutation();
  const [deleteTemplate, { isLoading: isDeleting, isError: isErrorDelete }] = useDeleteTemplateMutation();

  // VARS
  const isLoading = isFetching || isGetting || isUpdating || isDeleting;
  const isError = isErrorGet || isErrorUpdate || isErrorDelete;

  useEffect(() => {
    return () => {
      setSections(null);
      dispatch(builderApi.util.invalidateTags([{ type: "Template" }]));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // EDIT SECTION SETTINGS
  const onEdit = () => {
    setModal({
      template: data,
      isOpen: true,
      project: project,
      buttonText: i18n("button.update_section"),
      onSubmit: ({ id, title, key, thumbnail }) => {
        updateTemplate({ id, title, key, thumbnail }).then((res) => {
          if (res?.data) {
            reloadList();
            dispatch(builderApi.util.invalidateTags([{ type: "Template" }]));
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  // DROP ALL INPUTS IN STRUCTURE
  const onEmptize = () => {
    let sectionsRows = undefined;

    if (sections) {
      sectionsRows = { delete: [] };
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].section) {
          sectionsRows.delete.push({ id: sections[i].id });
        }
      }
    }

    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      title: i18n("label.clean_all_inputs"),
      forceNotice: data.inUse,
      notice: i18n("alert.action_clean_page"),
      onConfirm: () => {
        updateTemplate({ id: data.id, sections: sectionsRows }).then((res) => {
          if (res?.data) {
            setSections([]);
            showServerError(res);
            reloadList();
          }
          setConfirmModal(null);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  // ON DUPLICATE
  const onClickDuplicate = () => {
    onDuplicate(data?.sections);
  };

  // DELETE SECTION
  const onDelete = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      buttonText: i18n("button.delete"),
      title: i18n("label.delete_section"),
      text: i18n("alert.section_delete"),
      forceNotice: data.inUse,
      notice: i18n("alert.section_in_use"),
      ...(!data.inUse && {
        onConfirm: () => {
          deleteTemplate({ id: data.id }).then((res) => {
            if (res && res.data) {
              reloadList();
              navigate(`/project/${project}/builder/templates/`);
            } else {
              showServerError(res);
            }
          });
        },
      }),
      /*  onConfirm: !data.inUse
        ? () => {
            deleteTemplate({ id: data.id }).then((res) => {
              if (res && res.data) {
                reloadList();
                navigate(`/project/${project}/builder/templates/`);
              } else {
                showServerError(res);
              }
            });
          }
        : null, */
      onClose: () => setConfirmModal(null),
    });
  };

  // OPEN THUMBNAIL
  const onOpenPreview = () => {
    setPreviewModal({
      isOpen: true,
      list: sections?.map((item) => ({ name: item?.section?.title, image: item?.section?.thumbnail?.href })),
      onClose: () => setPreviewModal(null),
    });
  };

  // OPOEN MODAL
  const onOpenUsageLocation = () => {
    setModalUsageLocation({
      isOpen: true,
      data: data,
      modalType: "template",
      onClose: () => setModalUsageLocation(null),
    });
  };

  // ON SAVE STRUCTURE
  function onSave(list = [], history) {
    let sections = undefined;
    if (list) {
      sections = { create: [], update: [] };
      for (let i = 0; i < list.length; i++) {
        if (list[i].section) {
          sections.update.push({ id: list[i].id, order: i });
        } else {
          sections.create.push({ id: list[i].idSection, order: i });
        }
      }
    }

    updateTemplate({ id: data.id, sections, history }).then((res) => {
      if (res?.data) {
        if (!history) setSections(res?.data?.sections);
      } else {
        showServerError(res);
      }
    });
  }

  // UPDATE FROM HISTORY
  const onUseHistory = (isNext) => {
    if (!history || history?.length < 2) return null;

    const direction = isNext ? -1 : 1;
    const newActive = active + direction;

    if (newActive >= history.length || newActive < 0 || !history.at(newActive)) return null;

    setSections(history.at(newActive));
    dispatch(setActiveHistory(newActive));

    // STOP TIMER IF EXISTS
    if (timer) clearTimeout(timer);

    // UPDATE IN DATABASE
    const newTimer = setTimeout(() => {
      onSave(history.at(newActive), true);
    }, 1400);

    // SET NEW TIMER
    setTimer(newTimer);
  };

  const templateId = data?.id;
  useEffect(() => {
    if (templateId) setSections(data?.sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  if (!id) return <Navigate to={`/project/${project}/builder/templates`} />;
  if (isUninitialized) return <Loading active className="h-full flex items-center justify-center" />;

  return (
    <>
      <SortableTree
        title={data?.title}
        slug={data?.key}
        isLoading={isLoading}
        isError={isError}
        initialItems={sections?.length ? [...sections.map((item) => ({ id: item.id, title: item.section.title, section: !!item.section }))] : []}
        onSave={onSave}
        isTemplate
      >
        <History active={active} length={history?.length || 0} onNext={() => onUseHistory(true)} onPrev={() => onUseHistory(false)} />
        {sections?.some((section) => section.section?.thumbnail?.href) && (
          <button className="btn-primary-outline mx-2" onClick={onOpenPreview}>
            {i18n("label.preview")}
          </button>
        )}
        <Dropdown
          disabled={isLoading}
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
            { title: i18n("button.duplicate"), onClick: onClickDuplicate, icon: "copy" },
            { title: i18n("button.usage_location"), onClick: onOpenUsageLocation, icon: "info" }, //icon sitemap
            { title: i18n("button.cleanStructure"), onClick: onEmptize, icon: "clean" },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true },
          ]}
        />
      </SortableTree>

      <ModalUsageLocation {...modalUsageLocation} />
      <ModalTemplate {...modal} />
      <ModalPreview {...previewModal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
