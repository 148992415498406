import { forwardRef } from "react";
import classNames from "classnames";

// DND KIT
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

// COMPONENTS
import { Handle } from "../handle";
import { Action } from "../action";

// UTILS
import { iOS, animateLayoutChanges } from "../../utilities";
import { DocumentDuplicateIcon, LockClosedIcon, PencilIcon, StarIcon, TrashIcon } from "@heroicons/react/24/outline";

export default function SortableTreeItem({ id, depth, isValidDropTarget, ...props }) {
  const { attributes, isDragging, isSorting, listeners, setDraggableNodeRef, setDroppableNodeRef, transform, transition } = useSortable({ id, animateLayoutChanges });
  const style = { transform: CSS.Translate.toString(transform), transition };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      validTarget={isValidDropTarget}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
}

const TreeItem = forwardRef((props, ref) => {
  const {
    childCount,
    clone,
    depth,
    disableSelection,
    disableInteraction,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    collapsed,
    onCollapse,
    onRemove,
    onDuplicate,
    onEdit,
    style,
    wrapperRef,
    validTarget,
    title,
    type,
    slug,
    id,
    isSection,
    isTemplate,
    isEditCustom,
    onCustom,
    onRemoveCustom,
    customId,
    ...others
  } = props;

  return (
    <div
      ref={wrapperRef}
      className={classNames(
        "list-none border-box",
        clone && "inline-block pointer-events-none p-0 ml-2.5 mt-1 min-w-[400px]",
        ghost && "opacity-40",
        // indicator && styles.indicator,
        disableSelection && "select-none",
        disableInteraction && "pointer-events-none"
      )}
      style={{
        paddingLeft: `${indentationWidth * depth}px`,
        marginBottom: "4px",
      }}
      {...others}
    >
      <div className="relative flex items-center p-2.5 border border-gray-300 rounded-md text-gray-700 box-border bg-white" ref={ref} style={style}>
        <Handle {...handleProps} />
        {onCollapse && !customId && (
          <Action onClick={onCollapse}>
            <svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 41" className={classNames("transition-transform duration-300", collapsed && "-rotate-90")}>
              <path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
            </svg>
          </Action>
        )}
        <span className="flex flex-grow flex-grow-1 justify-between pl-1 whitespace-nowrap text-ellipsis overflow-hidden">
          <div className={`flex flex-col  ${isTemplate ? " pt-1.5" : ""}`}>
            <p className="font-bold inline-block">{title}</p>
            {!isTemplate && <span className="text-xs leading-none text-primary-400">#{slug}</span>}
          </div>

          {/* BUTTONS */}
          <div className="flex items-center gap-2">
            {/* TYPE */}
            {!clone && type && !isTemplate && !customId && <kbd className="text-[10px] bg-gray-400 text-white py-1 px-2 rounded-2xl">{type}</kbd>}
            {!clone && type && !isTemplate && customId && <kbd className="text-[10px] bg-yellow-400 text-yellow-800 py-1 px-2 rounded-2xl">Custom</kbd>}
            {/* CUSTOM */}
            {!clone && isSection && onCustom && type === "Group" && !(!customId && isEditCustom) && (
              <button
                type="button"
                onClick={customId ? onRemoveCustom : onCustom}
                className="p-2 aspect-square flex justify-center items-center border rounded transition-all duration-500 hover:text-white hover:bg-primary-500"
              >
                {customId ? <LockClosedIcon className="h-4" /> : <StarIcon className="h-4" />}
              </button>
            )}
            {/* EDIT */}
            {!clone && onEdit && !isTemplate && (
              <button type="button" onClick={onEdit} className="p-2 aspect-square flex justify-center items-center border rounded transition-all duration-500 hover:text-white hover:bg-primary-500">
                <PencilIcon className="h-4" />
              </button>
            )}
            {/* DUPLICATE */}
            {!clone && onDuplicate && !isTemplate && (
              <button
                type="button"
                onClick={onDuplicate}
                className="p-2 aspect-square flex justify-center items-center border rounded transition-all duration-500 hover:text-white hover:bg-primary-500"
              >
                <DocumentDuplicateIcon className="h-4" />
              </button>
            )}
            {/* DELETE */}
            {!clone && onRemove && (
              <button type="button" onClick={onRemove} className="p-2 aspect-square flex justify-center items-center border rounded transition-all duration-500 hover:text-white hover:bg-red-500">
                <TrashIcon className="h-4" />
              </button>
            )}
          </div>
        </span>
        {clone && childCount && childCount > 1 ? (
          <span className="absolute top-[-10px] right-[-10px] flex items-center justify-center w-[24px] h-[24px] rounded-full bg-primary-500 text-[0.8rem] font-semibold text-white">{childCount}</span>
        ) : null}
      </div>
    </div>
  );
});
